import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import { useState, useRef, useEffect } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/global.css';
import { FaFolder } from 'react-icons/fa';
import { MdGpsFixed, MdDownload } from 'react-icons/md';
import { api, getHeader } from '../auth/Authenticator';
// import Titulo from './components/Title';
import SidebarLeftFull from './components/Sidebar-Left-full'
import SidebarRightFull from './components/Sidebar-Right-Full';
import { FarmType } from './CadastroPropriedade';
import { createMapOnBahia, editableMap, getShapefileBuffer, goHome, openFile, salvarShapefile } from '../utils/mapas';
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar';
import { convertDate } from "../utils/strings";
import CustomToolbar from "./components/CustomToolbar";

function estadosVazios(): VazioType {
    return { id: '', codigo: '', municipio: '', inicio_soja: new Date(), final_soja: new Date(), inicio_algodao: new Date(), final_algodao: new Date(), shp: '', farms: [] };
}

export type VazioType = {
    id: string;
    inicio_soja: Date,
    final_soja: Date,
    inicio_algodao: Date,
    final_algodao: Date
    codigo: string;
    municipio: any;
    shp: string;
    farms: Array<FarmType>;
}

function CadastroVazio(this: any) {
    const mapRef = useRef<any>(null);
    const [vazio, setVazio] = useState<VazioType>(estadosVazios);
    const [mapa, setMapa] = useState<L.Map | null>(null);
    const nav = useNavigate()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    useEffect(() => {
        try {
            if (!mapa) {
                const map = createMapOnBahia(mapRef)
                editableMap(map, navigate)
                setMapa(map);
            }
        } catch (e) {
            console.log('Error:', e)
        }
    }, []);

    const [vazioSoja, setVazioSoja] = useState<Array<Date>>([])
    const [vazioAlgodao, setVazioAlgodao] = useState<Array<Date>>([])
    function changeField(event: any) {
        const { value, name } = event.target;
        if (name === 'vazioSoja') {
            setVazioSoja(value)
            setVazio({
                ...vazio,
                inicio_soja: value[0],
                final_soja: value[1]
            })
        } else if (name === 'vazioAlgodao') {
            setVazioAlgodao(value)
            setVazio({
                ...vazio,
                inicio_algodao: value[0],
                final_algodao: value[1]
            })
        }
        else
            setVazio({
                ...vazio,
                [name]: value
            });
        console.log('Vazio', vazio);
    }

    function cadastrar(event: React.FormEvent) {
        event.preventDefault();
        const fetchData = async () => {
            const formData = new FormData();

            formData.append('codigo', vazio.municipio.id)
            formData.append('municipio', vazio.municipio.name)
            formData.append('inicio_soja', convertDate(vazio.inicio_soja))
            formData.append('final_soja', convertDate(vazio.final_soja))
            formData.append('inicio_algodao', convertDate(vazio.inicio_algodao))
            formData.append('final_algodao', convertDate(vazio.final_algodao))
            formData.append('shp', await getShapefileBuffer(mapa))

            const requestOptions = {
                method: 'POST',
                headers: getHeader(true),
                body: formData,
            };

            try {
                const response = await fetch(api + 'vazios/', requestOptions)
                if (response.ok) {
                    const data = await response.json();
                    alert('Vazio do municipio ' + vazio.municipio + ' registrado com id: ' + data.id);
                    nav('/vazios')
                } else {
                    console.log('Erro:', response)
                    alert('Erro: ' + response)
                }
            } catch (error) {
                console.log("error: ", error);
                alert('Erro: ' + error)
            }
        }
        fetchData();
    }

    const [municipios, setMunicipios] = useState<Array<any>>([])
    useEffect(() => {
        const a = async () => {
            const response = await fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados/BA/municipios')
            if (response.ok) {
                const result = await response.json()
                const vetor: Array<any> = []
                // const vetor: Array<any> = []
                console.log('Quantidade de municipios:', result)
                result.forEach((municipio: any) => {
                    vetor.push({ name: municipio.nome + ' [' + municipio.id + ']', code: municipio.id })
                });
                setMunicipios(vetor)
            }
        }
        a()
    }, [])
    return (
        <div className='CadastroFarm'>
            {/* <Titulo titulo={'CADASTRO DE MONITORAMENTO'} loading={loading}/> */}
            <CustomToolbar titulo={'CADASTRO DE MONITORAMENTO'} loading={loading} />
            <SidebarLeftFull>
                <Link to='/home' className='button-sidebar-left-full'>
                    <FiArrowLeft size={35} />
                </Link>

                <form className='inputs'>
                    <div className='label'>MUNICÍPIO</div>
                    <Dropdown name='municipio' value={vazio.municipio} onChange={changeField} options={municipios} optionLabel="name"
                        placeholder='Selecione um município'
                        // place
                        filter
                        // className='w-full md:w-14rem'
                        className='field'
                        style={{ height: '60px', padding: '0px' }}

                    />
                    <div className='label'>VAZIO SOJA</div>
                    <div>
                        <Calendar
                            name='vazioSoja'
                            value={vazioSoja}
                            onChange={changeField}
                            dateFormat='dd/mm/yy'
                            selectionMode='range'
                            minDate={new Date(new Date().getFullYear() + '-01-02')}
                            maxDate={new Date((new Date().getFullYear() + 1) + '-01-01')}
                            showIcon
                            numberOfMonths={2}
                            showButtonBar
                            readOnlyInput
                            placeholder='Período Soja'
                        />
                    </div>

                    <div className='label'>PLANTIO ALGODÃO</div>
                    <Calendar
                        name='vazioAlgodao'
                        value={vazioAlgodao}
                        onChange={changeField}
                        dateFormat='dd/mm/yy'
                        selectionMode='range'
                        minDate={new Date(new Date().getFullYear() + '-01-02')}
                        maxDate={new Date((new Date().getFullYear() + 1) + '-01-01')}
                        showIcon
                        numberOfMonths={2}
                        showButtonBar
                        readOnlyInput
                        placeholder='Período Algodão'
                    />

                    <div className='label'>ANTECIPAÇÃO</div>
                    <Calendar
                        name='vazioAlgodao'
                        value={vazioAlgodao}
                        onChange={changeField}
                        dateFormat='dd/mm/yy'
                        selectionMode='range'
                        minDate={new Date(new Date().getFullYear() + '-01-02')}
                        maxDate={new Date((new Date().getFullYear() + 1) + '-01-01')}
                        showIcon
                        numberOfMonths={2}
                        showButtonBar
                        readOnlyInput
                        placeholder='Período Algodão'
                    />
                    <button type="submit" className='buttonCadastro' onClick={cadastrar}>CADASTRAR</button>
                </form>
            </SidebarLeftFull>

            <SidebarRightFull>
                <label className='button-sidebar-right-full' htmlFor="arquivo"><FaFolder size={33} /></label>
                <input type="file" onChange={e => openFile(e, mapa)} id='arquivo' name='arquivo' accept='.shp,.kml,.zip' />
                <button className='button-sidebar-right-full' onClick={() => goHome(mapa)}> <MdGpsFixed size={33} /> </button>
                <button className='button-sidebar-right-full' onClick={() => salvarShapefile(mapa)}> <MdDownload size={33} /> </button>
            </SidebarRightFull>

            <div id='map' ref={mapRef} />
        </div>
    );
}

export default CadastroVazio;
