import '../../styles/pages/components/Sidebar-Right-Full.css'

type Props = {
    children?: React.ReactNode
}

function SidebarRightFull({ children }: Props) {

    return (
        <div className='sidebar-right-full'>
            {children}
        </div>
    )
}

export default SidebarRightFull;