import Rotas from './rotas';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';

function App() {
  return (
    <PrimeReactProvider>
      <Rotas />
    </PrimeReactProvider>

  );
}

export default App;
