import '../../styles/pages/components/Subtitle.css'
import L from 'leaflet';

type FilterProps = {
    map: L.Map | null,

    children?: React.ReactNode
}

function Subtitulo(props: FilterProps): JSX.Element {

    return (
        <div className='subtitle'>
            {props.children && props.children}
        </div>
    )
}



export default Subtitulo; 
