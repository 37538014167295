import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Login from './pages/Login'
import Home from './pages/Home'
import Municipio from './pages/Municipio'
import Propriedade from './pages/Propriedade'
import CadastroPropriedade from './pages/CadastroPropriedade'
import CadastroVazio from './pages/CadastroVazio'
import CadastroCultivo from './pages/CadastroCultivo'

function Rotas() { 
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/municipio" element={<Municipio />} />
        <Route path="/propriedade" element={<Propriedade />} />
        <Route path="/cadastroPropriedade" element={<CadastroPropriedade />} />
        <Route path="/cadastroCultivo" element={<CadastroCultivo />} />
        <Route path="/cadastroVazio" element={<CadastroVazio />} />
      </Routes>
    </BrowserRouter> 
  );
}

export default Rotas; 