import { useState, ChangeEvent, useEffect, useRef } from 'react';
// import { RiSave3Fill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import '../styles/global.css';
import { api, getHeader } from '../auth/Authenticator';
import { FarmType } from './CadastroPropriedade';
import axios from 'axios'
import { isCPFValid, isCNPJValid } from '../utils/cadastro';
import { SelectButton } from 'primereact/selectbutton';
import { InputText } from 'primereact/inputtext';
import { InputMask, InputMaskChangeEvent } from "primereact/inputmask";
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { MenuItem } from 'primereact/menuitem';
import { Steps } from 'primereact/steps';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { getPessoa, getPessoas } from '../auth/API';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import * as messages from '../utils/messages'
import { Tooltip } from 'primereact/tooltip';
import { FileUpload, FileUploadHandlerEvent, FileUploadHeaderTemplateOptions, FileUploadSelectEvent, FileUploadUploadEvent, ItemTemplateOptions, } from 'primereact/fileupload';
import { Tag } from 'primereact/tag';
import { ProgressBar } from 'primereact/progressbar';
import { ProgressSpinner } from 'primereact/progressspinner';

export type PessoaType = {
    id: string;
    nome_razao: string;
    cpf_cnpj: string;
    rg_insc: string;
    paper: any;
    cep: string;
    endereco: string;
    numero: string;
    bairro: string;
    cidade: string;
    uf: string;
    conj_resp: number | null;
    celular: string;
    email: string;
    farms: Array<FarmType>;
    doc_cpf: any;
    doc_rg: any;
    doc_res: any;
    history: any;
}

export function newPessoa(): PessoaType {
    return {
        id: '',
        nome_razao: '',
        cpf_cnpj: '',
        rg_insc: '',
        paper: null,
        cep: '',
        endereco: '',
        numero: '',
        bairro: '',
        cidade: '',
        uf: '',
        conj_resp: null,
        celular: '',
        email: '',
        farms: [],
        doc_cpf: null,
        doc_rg: null,
        doc_res: null,
        history: null
    };
}

type Props = {
    state: boolean
    function: Function
    pessoa: PessoaType
    setPessoa: Function
    activeIndex: number
    setActiveIndex: Function
}

export function CadastroPessoa(props: Props) {
    // console.log('Cadastro Pessoa Dialog Props:', props)
    // const [user, setUser] = useState<PessoaType>(props.pessoa?props.pessoa:newPessoa)
    const navigate = useNavigate()
    const labelsNome = ['Nome completo', 'Razão social']
    const [labelRazao, setLabelRazao] = useState<string>(labelsNome[0])
    const labelsDocsCPF = ['CPF', 'Cartão CNPJ']
    const [labelDocCPF, setLabelDocCPF] = useState<string>(labelsDocsCPF[0])
    const labelsDocsRG = ['RG', 'Contrato Social']
    const [labelDocRG, setLabelDocRG] = useState<string>(labelsDocsRG[0])
    const labelsDocsComp = ['Comprovante de Residência', 'Comprovante de Endereço']
    const [labelDocComp, setLabelDocComp] = useState<string>(labelsDocsComp[0])
    const labelsDocumentacao1 = ['CPF', 'CNPJ']
    const [labelCNPJ, setLabelCNPJ] = useState<string>(labelsDocumentacao1[0])
    const labelsDocumentacao2 = ['RG', 'Inscrição estadual']
    const [labelInsc, setLabelInsc] = useState<string>(labelsDocumentacao2[0])
    const mascarasCadastro = ['999.999.999-99', '99.999.999/9999-99']
    const [maskCNPJ, setMaskCNPJ] = useState<string>(mascarasCadastro[0])
    const holderRazoes = ['Digite o nome completo', 'Digite a razão social']
    const [holderRazao, setHolderRazao] = useState<string>(holderRazoes[0])
    const relacionamentos = ['Cônjuge', 'Responsável legal']
    const [labelRelacionamento, setLabelRelacionamento] = useState<string>(relacionamentos[0])
    const [invalidCPFCNPJMessage, setinvalidCPFCNPJMessage] = useState<string | null>(null)
    const [loadingAddress, setLoadingAddress] = useState(false)
    const tipos = ['Pessoa Física', 'Pessoa Jurídica']
    const [tipo, setTipo] = useState<string>(tipos[0])
    const [pessoas, setPessoas] = useState<any>([])

    const a = async () => {
        const p = await getPessoas(navigate, () => messages.errorMessage(toast.current))
        const pp = p.map((p: any) => {
            return { code: p.id, name: p.nome_razao }
        })
        setPessoas(pp)
    }
    useEffect(() => {
        a()
        console.log('Props', props)
    }, [])
    const consultCEP = async (cep: string) => {
        const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
        return response.data
    }
    const validateCPFCNPJ = async (value: string) => {
        if (value.length > 0 && value.indexOf('_') < 0) {

            if (tipo === tipos[0]) {
                if (isCPFValid(value))
                    setErrorCPFCNPJ('')
                else
                    setErrorCPFCNPJ('* CPF inválido')
                const p = await getPessoa(value, null, () => { })
                if (p !== null)
                    setErrorCPFCNPJ('CPF já cadastrado!')
            }
            else if (tipo === tipos[1]) {
                if (isCNPJValid(value))
                    setErrorCPFCNPJ('')
                else
                    setErrorCPFCNPJ('CNPJ inválido')
                const p = await getPessoa(value, null, () => { })
                if (p !== null)
                    setErrorCPFCNPJ('CNPJ já cadastrado!')
            }
        } else setErrorCPFCNPJ('')
    }
    const changeField = async (event: any) => {
        var { value, name } = event.target;
        console.log('Name', name, 'Value', value)
        value = value.toUpperCase()
        props.setPessoa && props.setPessoa({
            ...props.pessoa,
            [name]: value
        })
        if (name === 'nome_razao') {
            setErrorNome('')
        } else if (name === 'cpf_cnpj') {
            setErrorCPFCNPJ('')
            validateCPFCNPJ(value)
        } else if (name === 'logradouro') {
            setErrorLogradouro('')
        } else if (name === 'cidade') {
            setErrorCidade('')
        } else if (name === 'uf') {
            setErrorUF('')
        } else if (name === 'cep') {
            setErrorCEP('')
            if (value.indexOf('_') < 0) {
                const cep = value.replaceAll('.', '').replaceAll('-', '')
                const addr = await consultCEP(cep)
                if (addr.erro)
                    props.setPessoa({
                        ...props.pessoa,
                        cep: value,
                        endereco: '',
                        bairro: '',
                        cidade: '',
                        uf: ''
                    })
                else {
                    setErrorCEP('')
                    setErrorLogradouro('')
                    setErrorCidade('')
                    setErrorUF('')
                    props.setPessoa({
                        ...props.pessoa,
                        cep: addr.cep,
                        endereco: addr.logradouro.toUpperCase(),
                        bairro: addr.bairro.toUpperCase(),
                        cidade: addr.localidade.toUpperCase(),
                        uf: addr.uf
                    })
                }
                setLoadingAddress(false)
                console.log(props.pessoa)
            } else {
                props.setPessoa({
                    ...props.pessoa,
                    cep: value,
                    endereco: '',
                    bairro: '',
                    cidade: '',
                    uf: ''
                })
                setLoadingAddress(true)

            }
        } else if (name === 'celular') {
            setErrorCelular('')
        } else if (name === 'email') {
            setErrorEmail('')
        }
    }
    const [waiting, setWaiting] = useState(false)
    function cadastrar(event: React.FormEvent) {
        setWaiting(true)
        event.preventDefault();
        const fetchData = async () => {
            console.log('URL:', api + 'pessoas/' + (props.pessoa.id && (props.pessoa.id + '/')))
            try {
                const formData = new FormData();
                formData.append("nome_razao", props.pessoa.nome_razao)
                formData.append("cpf_cnpj", props.pessoa.cpf_cnpj)
                formData.append("rg_insc", props.pessoa.rg_insc)
                formData.append("conjuge", '-')
                formData.append("cep", props.pessoa.cep)
                formData.append("endereco", props.pessoa.endereco)
                formData.append("numero", props.pessoa.numero)
                formData.append("bairro", props.pessoa.bairro)
                formData.append("cidade", props.pessoa.cidade)
                formData.append("uf", props.pessoa.uf)
                formData.append("celular", props.pessoa.celular)
                formData.append("email", props.pessoa.email)
                console.log('Files', files)
                if (files[0] !== null) formData.append("doc_cpf", files[0])
                if (files[1] !== null) formData.append("doc_rg", files[1])
                if (files[2] !== null) formData.append("doc_res", files[2])
                // formData.append("// password", '1')
                // formData.append("paper", null)
                formData.append("history", '1')

                const response = await fetch(api + 'pessoas/' + (props.pessoa.id && (props.pessoa.id + '/')),
                    {
                        method: props.pessoa.id ? 'PUT' : 'POST',
                        mode: 'cors',
                        headers: getHeader(true),
                        body: formData
                        // body: JSON.stringify({
                        //     nome_razao: props.pessoa.nome_razao,
                        //     cpf_cnpj: props.pessoa.cpf_cnpj,
                        //     rg_insc: props.pessoa.rg_insc,
                        //     conjuge: '-',
                        //     cep: props.pessoa.cep,
                        //     endereco: props.pessoa.endereco,
                        //     numero: props.pessoa.numero,
                        //     bairro: props.pessoa.bairro,
                        //     cidade: props.pessoa.cidade,
                        //     uf: props.pessoa.uf,
                        //     celular: props.pessoa.celular,
                        //     email: props.pessoa.email,
                        //     doc_cpf: files[0],
                        //     doc_rg: files[1],
                        //     doc_res: files[3],
                        //     // password: '1',
                        //     paper: null,
                        //     history: '1'
                        // }),
                    });
                console.log(response)
                if (response.status === 201) {//CADASTRO OK
                    const data = await response.json();
                    console.log('Data', data);
                    var id = '';
                    if (data.url)
                        id = data.url?.replace(api + 'ADAB-farms/', '').replace("/", "");
                    else
                        id = data.id && data.id
                    console.log('Id', id)
                    props.setActiveIndex(0)
                    savedPessoa(id)
                    props.setPessoa(newPessoa())
                } else if (response.status === 200) { //ATUALIZACAO OK
                    const data = await response.json();
                    console.log('Data', data);
                    var id = '';
                    if (data.url)
                        id = data.url?.replace(api + 'ADAB-farms/', '').replace("/", "");
                    else
                        id = data.id && data.id
                    console.log('Waiting', waiting)
                    setWaiting(false)
                    console.log('Waiting after', waiting)
                    updatedPessoa(id)
                } else if (response.status === 400) {
                    const data = await response.json();
                    console.log('Data', data)
                    alert(response.statusText + '\n' + data)
                } else {
                    const data = await response.json();
                    console.log('Data', data)
                    alert(response.statusText + '\n' + data)
                }
            } catch (error) {
                console.log("error", error);
            }
        }
        fetchData();
    }

    const handleTipo = async (event: any) => {
        setTipo(event.value)
        var t = event.value === tipos[0] ? 0 : 1
        setMaskCNPJ(mascarasCadastro[t])
        setHolderRazao(holderRazoes[t])
        setLabelRazao(labelsNome[t])
        setLabelCNPJ(labelsDocumentacao1[t])
        setLabelInsc(labelsDocumentacao2[t])
        setLabelRelacionamento(relacionamentos[t])
        setLabelDocCPF(labelsDocsCPF[t])
        setLabelDocRG(labelsDocsRG[t])
        setLabelDocComp(labelsDocsComp[t])
    }
    useEffect(() => {
        validateCPFCNPJ(props.pessoa.cpf_cnpj)
    }, [tipo])

    const toast = useRef<Toast | null>(null);
    const toastBC = useRef<Toast | null>(null);
    // const [activeIndex, setActiveIndex] = useState(0)
    const [errorNome, setErrorNome] = useState('')
    const [errorCPFCNPJ, setErrorCPFCNPJ] = useState('')
    const [errorCEP, setErrorCEP] = useState('')
    const [errorLogradouro, setErrorLogradouro] = useState('')
    const [errorCidade, setErrorCidade] = useState('')
    const [errorUF, setErrorUF] = useState('')
    const [errorCelular, setErrorCelular] = useState('')
    const [errorEmail, setErrorEmail] = useState('')
    const verifyData = (event: any) => {
        if (event.index > props.activeIndex) {
            if (props.activeIndex === 0) {
                if (props.pessoa.nome_razao.length < 1) {
                    toast.current && toast.current.show({ severity: 'error', summary: 'Dados pessoais', detail: 'Nome não informado!' });
                    setErrorNome('* Campo obrigatório')
                    props.setActiveIndex(props.activeIndex)
                } else if (tipo === tipos[0] && props.pessoa.nome_razao.indexOf(' ') < 0) {
                    toast.current && toast.current.show({ severity: 'error', summary: 'Dados pessoais', detail: 'Nome incompleto!' });
                    setErrorNome('* Nome incompleto')
                    props.setActiveIndex(props.activeIndex)
                } else if (tipo === tipos[0] && props.pessoa.cpf_cnpj.length < 1) {
                    toast.current && toast.current.show({ severity: 'error', summary: 'Dados pessoais', detail: 'CPF não informado!' });
                    setErrorCPFCNPJ('* Campo obrigatório')
                    props.setActiveIndex(props.activeIndex)
                } else if (tipo === tipos[1] && props.pessoa.cpf_cnpj.length < 1) {
                    toast.current && toast.current.show({ severity: 'error', summary: 'Dados pessoais', detail: 'CNPJ não informado!' });
                    setErrorCPFCNPJ('* Campo obrigatório')
                    props.setActiveIndex(props.activeIndex)
                } else if (tipo === tipos[0] && props.pessoa.cpf_cnpj.indexOf('_') > -1) {
                    toast.current && toast.current.show({ severity: 'error', summary: 'Dados pessoais', detail: 'CPF incompleto!' });
                    setErrorCPFCNPJ('* CPF incompleto')
                    props.setActiveIndex(props.activeIndex)
                } else if (tipo === tipos[1] && props.pessoa.cpf_cnpj.indexOf('_') > -1) {
                    toast.current && toast.current.show({ severity: 'error', summary: 'Dados pessoais', detail: 'CNPJ incompleto!' });
                    setErrorCPFCNPJ('* CNPJ incompleto')
                    props.setActiveIndex(props.activeIndex)
                } else if (tipo === tipos[0] && !isCPFValid(props.pessoa.cpf_cnpj)) {
                    toast.current && toast.current.show({ severity: 'error', summary: 'Dados pessoais', detail: 'CPF inválido!' });
                    setErrorCPFCNPJ('* CPF inválido')
                    props.setActiveIndex(props.activeIndex)
                } else if (tipo === tipos[1] && !isCNPJValid(props.pessoa.cpf_cnpj)) {
                    toast.current && toast.current.show({ severity: 'error', summary: 'Dados pessoais', detail: 'CNPJ inválido!' });
                    setErrorCPFCNPJ('* CNPJ inválido')
                    props.setActiveIndex(props.activeIndex)
                } else {
                    setErrorNome('')
                    setErrorCPFCNPJ('')
                    // toast.current.show({ severity: 'success', summary: 'Dados pessoais', detail: 'Informações válidas!' });
                    props.setActiveIndex(event.index)
                }
            } else if (props.activeIndex === 1) {
                if (props.pessoa.cep.length < 1) {
                    toast.current && toast.current.show({ severity: 'error', summary: 'Endereço', detail: 'CEP não informado!' });
                    setErrorCEP('* Campo obrigatório')
                    props.setActiveIndex(props.activeIndex)
                } else if (props.pessoa.cep.indexOf('_') > -1) {
                    toast.current && toast.current.show({ severity: 'error', summary: 'Endereço', detail: 'CEP incompleto!' });
                    setErrorCEP('* CEP incompleto')
                    props.setActiveIndex(props.activeIndex)
                } else if (props.pessoa.cidade.length < 1) {
                    toast.current && toast.current.show({ severity: 'error', summary: 'Endereço', detail: 'Cidade não informada!' });
                    setErrorCidade('* Campo obrigatório')
                    props.setActiveIndex(props.activeIndex)
                } else if (props.pessoa.uf.length < 1) {
                    toast.current && toast.current.show({ severity: 'error', summary: 'Endereço', detail: 'UF não informada!' });
                    setErrorUF('* Campo obrigatório')
                    props.setActiveIndex(props.activeIndex)
                } else {
                    // toast.current.show({ severity: 'success', summary: 'Dados pessoais', detail: 'Informações válidas!' });
                    props.setActiveIndex(event.index)
                }

            } else if (props.activeIndex === 2) {
                if (props.pessoa.celular.length < 1) {
                    toast.current && toast.current.show({ severity: 'error', summary: 'Contato', detail: 'Celular não informado!' });
                    setErrorCelular('* Campo obrigatório')
                    props.setActiveIndex(props.activeIndex)

                } else if (props.pessoa.celular.indexOf('_') > -1) {
                    toast.current && toast.current.show({ severity: 'error', summary: 'Contato', detail: 'Celular não informado!' });
                    setErrorCelular('* Celular incompleto')
                    props.setActiveIndex(props.activeIndex)

                } else if (props.pessoa.email.length < 1) {
                    toast.current && toast.current.show({ severity: 'error', summary: 'Contato', detail: 'Email não informado!' });
                    setErrorEmail('* Campo obrigatório')
                    props.setActiveIndex(props.activeIndex)
                } else if (props.pessoa.email.indexOf('@') < 0 || props.pessoa.email.indexOf('.') < 0) {
                    toast.current && toast.current.show({ severity: 'error', summary: 'Contato', detail: 'Email não informado!' });
                    setErrorEmail('* Email inválido')
                    props.setActiveIndex(props.activeIndex)
                } else {
                    // toast.current.show({ severity: 'success', summary: 'Dados pessoais', detail: 'Informações válidas!' });
                    props.setActiveIndex(event.index)
                }

            } else if (props.activeIndex === 3) {
                if (tipo === tipos[1] && props.pessoa.conj_resp === null) {
                    toast.current && toast.current.show({ severity: 'error', summary: 'Relacionamento', detail: 'Responsável legal não informado!' });
                    //     setActiveIndex(activeIndex)
                    // } else {
                    // toast.current.show({ severity: 'success', summary: 'Dados pessoais', detail: 'Informações válidas!' });
                    props.setActiveIndex(event.index)
                }
            }
        } else props.setActiveIndex(event.index)
    }
    const items: MenuItem[] = [
        {
            label: 'Dados pessoais',
            command: (event) => verifyData(event),
        },
        {
            label: 'Endereço',
            command: verifyData
        },
        {
            label: 'Contato',
            command: verifyData
        },
        {
            label: 'Documentos',
            command: verifyData
        }
    ];

    const accept = () => {
        props.setActiveIndex(0)
    }

    const reject = () => {
        props.function(false)
    }

    const savedPessoa = (id: any) => {
        confirmDialog({
            message: 'Usuário cadastrado com sucesso de id ' + id + '! Deseja adicionar outro usuário?',
            header: 'Cadastro de Usuário',
            icon: 'pi pi-exclamation-triangle',
            // defaultFocus: 'accept',
            accept,
            reject
        });
    };

    const updatedPessoa = (id: any) => {
        messages.message(toastBC.current, 'Usuário de id ' + id + ' atualizado com sucesso!')
    };

    const [totalSizes, setTotalSizes] = useState<Array<number>>([0, 0, 0]);
    // const [totalSize2, setTotalSize2] = useState(0);
    // const [totalSize3, setTotalSize3] = useState(0);

    const fileUploadRef0 = useRef<FileUpload>(null);
    const fileUploadRef1 = useRef<FileUpload>(null);
    const fileUploadRef2 = useRef<FileUpload>(null);


    const [files, setFiles] = useState<Array<File | null>>([props.pessoa.doc_cpf, props.pessoa.doc_rg, props.pessoa.doc_res])
    if (props.pessoa.doc_res)
        fetch(props.pessoa.doc_res).then((response: any) => { return response.blob() }).then((blob: any) => {
            // const blobUrl = URL.createObjectURL(blob)
            // console.log('Blob URL', blobUrl)
            // window.open(blobUrl)
            files[2] = new File([blob], 'ComprovanteResidencial', { type: blob.type })
            totalSizes[2] = files[2].size
        })

    const onTemplateSelect = (e: FileUploadSelectEvent, f: number) => {
        totalSizes[f - 1] = e.files[0].size
        files[f - 1] = e.files[0]
    };

    const onTemplateUpload = (e: FileUploadUploadEvent, f: number) => {
        totalSizes[f - 1] = e.files[0].size
        toast.current?.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    };

    const onTemplateRemove = (file: File, callback: Function, f: number) => {
        console.log('File:', file, 'Callback:', callback, 'F:', f)
        totalSizes[f - 1] = 0
        if (callback) callback();
    };

    const onTemplateClear = (f: number) => {
        totalSizes[f - 1] = 0
    };

    const headerTemplate = (options: FileUploadHeaderTemplateOptions, f: number) => {
        console.log('Options:', options, 'F:', f)

        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSizes[f - 1] / 30000;
        var formatedValue
        if (f === 1)
            formatedValue = fileUploadRef0 && fileUploadRef0.current ? fileUploadRef0.current.formatSize(totalSizes[f - 1]) : '0 B';
        if (f === 2)
            formatedValue = fileUploadRef1 && fileUploadRef1.current ? fileUploadRef1.current.formatSize(totalSizes[f - 1]) : '0 B';
        if (f === 3)
            formatedValue = fileUploadRef2 && fileUploadRef2.current ? fileUploadRef2.current.formatSize(totalSizes[f - 1]) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {/* {uploadButton} */}
                {cancelButton}
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue} / 3 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (file: any, props: ItemTemplateOptions, f: number) => {
        console.log('File in ItemTemplate():', file)
        // const file = files[f]
        if (file)
            return (
                <div className="flex align-items-center flex-wrap">
                    <div className="flex align-items-center" style={{ width: '40%' }}>
                        <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                        <span className="flex flex-column text-left ml-3">
                            {file.name}
                            <small>{new Date().toLocaleDateString()}</small>
                        </span>
                    </div>
                    <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                    <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove, f)} />
                </div>
            );
    };

    const emptyTemplate = (e: any, f: number) => {
        const a: any = ''
        console.log('Files:', files)
        console.log('F:', f)
        console.log('File (' + f + '):', files[f - 1])
        if (files[f - 1] !== null)
            return itemTemplate(files[f - 1], a, f)
        return (
            <div className="flex align-items-center flex-column" style={{ textAlign: 'center' }}>
                <i className="pi pi-image mt-3 p-5" style={{
                    fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)',
                    // width: '100%', height: '100%'
                }}></i>
                <br></br>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Arraste e solte aqui
                </span>
            </div>
        );
    };

    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };


    return (
        <Dialog className='CadastroUser' header="CADASTRO DE PESSOA" visible={props.state} style={{ width: '50vw' }} onHide={() => props.function(false)}>
            <ConfirmDialog />
            <Toast ref={toast} />
            <Toast ref={toastBC} position="center" onRemove={() => toastBC.current && messages.clear(toastBC.current)} />
            <Steps model={items} activeIndex={props.activeIndex} onSelect={(e: any) => props.setActiveIndex(e.index)} readOnly={false} />
            <br />
            {props.activeIndex === 0 && <div>
                <Divider align='left'>
                    <div className="inline-flex align-items-center">
                        <i className="pi pi-briefcase"></i>
                        <b> Tipo</b>
                    </div>
                </Divider>

                <div className="card flex justify-content-center">
                    <SelectButton value={tipo} onChange={handleTipo} options={tipos} />
                </div>

                <Divider align='left'>
                    <div className="inline-flex align-items-center">
                        <i className="pi pi-user"></i>
                        <b> {labelRazao}</b>
                    </div>
                </Divider>
                <br />
                <div className="flex flex-column gap-2">
                    <div className="flex flex-column p-inputgroup flex-1">
                        <span className="p-inputgroup-addon"><i className="pi pi-user"></i></span>
                        <span className="p-float-label">
                            <InputText id="username" name='nome_razao' value={props.pessoa.nome_razao} onChange={changeField} />
                            <label htmlFor="username">{holderRazao}</label>
                        </span>
                    </div>
                    <small style={{ color: 'red' }}>{errorNome}</small>
                </div>

                <Divider align='left'>
                    <div className="inline-flex align-items-center">
                        <i className="pi pi-id-card"></i>
                        <b> Dados de identificação</b>
                    </div>
                </Divider>
                <br />
                <div style={{ display: 'flex', width: '100%' }}>
                    <div className="flex flex-column gap-2" style={{ width: '100%' }}>
                        <div className="p-inputgroup flex-1">
                            <span className="p-inputgroup-addon"><i className="pi pi-id-card"></i></span>
                            <span className="p-float-label">
                                <InputMask id="ssn_inputa" name='cpf_cnpj'
                                    value={props.pessoa.cpf_cnpj}
                                    mask={maskCNPJ}
                                    onChange={changeField}
                                />
                                <label htmlFor="ssn_inputa">{labelCNPJ}</label>
                            </span>
                        </div>
                        <small style={{ color: 'red' }}>{errorCPFCNPJ}    </small>
                    </div>
                    <span>&nbsp;</span>
                    <div className="flex flex-column gap-2" style={{ width: '100%' }}>
                        <div className="p-inputgroup flex-1">
                            <span className="p-inputgroup-addon"><i className="pi pi-id-card"></i></span>
                            <span className="p-float-label">
                                <InputText id="rg_insc_input" name='rg_insc'
                                    keyfilter="int"
                                    value={props.pessoa.rg_insc}
                                    onChange={changeField}
                                />
                                <label htmlFor="rg_insc_input">{labelInsc}</label>
                            </span>
                        </div>
                        {/* <small id="username-help">Campo opcional</small> */}
                    </div>
                </div>
            </div>}

            {props.activeIndex === 1 &&
                <div><Divider align='left'>
                    <div className="inline-flex align-items-center">
                        <i className="pi pi-map-marker"></i>
                        <b> Endereço</b>
                    </div>
                </Divider>
                    <br />
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div className="flex flex-column gap-2" style={{ width: '30%' }}>
                            <div className="flex flex-column p-inputgroup flex-1">
                                <span className="p-inputgroup-addon"><i className="pi pi-map-marker"></i></span>
                                <span className="p-float-label">
                                    <InputMask id="ssn_input" name='cep'
                                        value={props.pessoa.cep} onChange={changeField}
                                        mask="99999-999" />
                                    <label htmlFor="ssn_input">CEP</label>
                                </span>
                            </div>
                            <small style={{ color: 'red' }}>{errorCEP}</small>
                        </div>
                        <span>&nbsp;</span>
                        <div className="flex flex-column gap-2" style={{ width: '50%' }}>
                            <div className="flex flex-column p-inputgroup flex-1">
                                <span className="p-inputgroup-addon"><i className="pi pi-compass"></i></span>
                                <span className="p-float-label p-input-icon-right">
                                    {loadingAddress && <i className="pi pi-spin pi-spinner" />}
                                    <InputText id="ssn_input" name='endereco' value={props.pessoa.endereco} onChange={changeField} />
                                    <label htmlFor="ssn_input">Logradouro</label>
                                </span>
                            </div>
                            <small style={{ color: 'red' }}>{errorLogradouro}</small>
                        </div>
                        <span>&nbsp;</span>
                        <div className="flex flex-column gap-2" style={{ width: '20%' }}>
                            <div className="p-inputgroup flex-1">
                                <span className="p-inputgroup-addon">00</span>
                                <span className="p-float-label">
                                    <InputNumber id="ssn_input" name='numero' value={Number(props.pessoa.numero)} onChange={changeField} />
                                    <label htmlFor="ssn_input">Número</label>
                                </span>
                            </div>
                        </div>
                    </div>

                    <br />
                    <br />

                    <div style={{ display: 'flex', width: '100%' }}>
                        <div className="flex flex-column gap-2" style={{ width: '40%' }}>
                            <div className="flex flex-column p-inputgroup flex-1">
                                <span className="p-inputgroup-addon"><i className="pi pi-sitemap"></i></span>
                                <span className="p-float-label p-input-icon-right">
                                    {loadingAddress && <i className="pi pi-spin pi-spinner" />}
                                    <InputText id="ssn_input" name='bairro' value={props.pessoa.bairro} onChange={changeField} />
                                    <label htmlFor="ssn_input">Bairro</label>
                                </span>
                            </div>
                        </div>
                        <span>&nbsp;</span>
                        <div className="flex flex-column gap-2" style={{ width: '40%' }}>
                            <div className="flex flex-column p-inputgroup flex-1">
                                <span className="p-inputgroup-addon"><i className="pi pi-map"></i></span>
                                <span className="p-float-label p-input-icon-right">
                                    {loadingAddress && <i className="pi pi-spin pi-spinner" />}
                                    <InputText id="ssn_input" name='cidade' value={props.pessoa.cidade} onChange={changeField} />
                                    <label htmlFor="ssn_input">Cidade</label>
                                </span>
                            </div>
                            <small style={{ color: 'red' }}>{errorCidade}</small>
                        </div>
                        <span>&nbsp;</span>
                        <div className="flex flex-column gap-2" style={{ width: '20%' }}>
                            <div className="p-inputgroup flex-1">
                                <span className="p-inputgroup-addon"><i className='pi pi-flag'></i></span>
                                <span className="p-float-label p-input-icon-right">
                                    {loadingAddress && <i className="pi pi-spin pi-spinner" />}
                                    <InputText id="ssn_input" name='uf' value={props.pessoa.uf} onChange={changeField} />
                                    <label htmlFor="ssn_input">UF</label>
                                </span>
                            </div>
                            <small style={{ color: 'red' }}>{errorUF}</small>
                        </div>

                    </div>
                </div>
            }
            {props.activeIndex === 2 &&
                <div>
                    <Divider align='left'>
                        <div className="inline-flex align-items-center">
                            <i className="pi pi-envelope"></i>
                            <b> Contato</b>
                        </div>
                    </Divider>
                    <br />
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div className="flex flex-column gap-2" style={{ width: '30%' }}>
                            <div className="p-inputgroup flex-1">
                                <span className="p-inputgroup-addon"><i className="pi pi-phone"></i></span>
                                <span className="p-float-label">
                                    <InputMask id="ssn_inputa" name='celular'
                                        value={props.pessoa.celular}
                                        mask={'(99) 9 9999-9999'}
                                        onChange={changeField}
                                    />
                                    <label htmlFor="ssn_inputa">Celular</label>
                                </span>
                            </div>
                            <small style={{ color: 'red' }}>{errorCelular}</small>
                        </div>
                        <span>&nbsp;</span>
                        <div className="flex flex-column gap-2" style={{ width: '70%' }}>
                            <div className="p-inputgroup flex-1">
                                <span className="p-inputgroup-addon"><i className="pi pi-at"></i></span>
                                <span className="p-float-label">
                                    <InputText id="rg_insc_input" name='email'
                                        keyfilter="email"
                                        value={props.pessoa.email}
                                        onChange={changeField}
                                    />
                                    <label htmlFor="rg_insc_input">E-mail</label>
                                </span>
                            </div>
                            <small style={{ color: 'red' }}>{errorEmail}</small>
                        </div>
                    </div>
                    <br />
                    <div>
                        <Divider align='left'>
                            <div className="inline-flex align-items-center">
                                <i className="pi pi-users"></i>
                                <b> {labelRelacionamento}</b>
                            </div>
                        </Divider>
                        <Dropdown name='conj_resp' value={props.pessoa.conj_resp} onChange={changeField} options={pessoas} optionLabel="name" placeholder="Selecione uma pessoa" filter className="w-full md:w-14rem" />
                    </div>
                </div >
            }
            {props.activeIndex === 3 &&
                <div>
                    <Divider align='left'>
                        <div className="inline-flex align-items-center">
                            <i className="pi pi-users"></i>
                            <b> {labelDocCPF}</b>
                        </div>
                    </Divider>
                    <Tooltip target=".custom-choose-btn" content="Selecionar arquivo" position="bottom" />
                    <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
                    <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

                    <FileUpload ref={fileUploadRef0} name="doc_cpf" url="/api/upload" accept="image/*" maxFileSize={30000000}
                        onUpload={(e) => onTemplateUpload(e, 1)} onSelect={(e) => onTemplateSelect(e, 1)} onError={() => onTemplateClear(1)} onClear={() => onTemplateClear(1)}
                        headerTemplate={(e) => headerTemplate(e, 1)} itemTemplate={(e, f) => itemTemplate(files[0], f, 1)} emptyTemplate={(e) => emptyTemplate(e, 1)}
                        chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
                    <Divider align='left'>
                        <div className="inline-flex align-items-center">
                            <i className="pi pi-users"></i>
                            <b> {labelDocRG}</b>
                        </div>
                    </Divider>


                    <FileUpload ref={fileUploadRef1} name="doc_rg" accept="image/*" maxFileSize={30000000}
                        onUpload={(e) => onTemplateUpload(e, 2)} onSelect={(e) => onTemplateSelect(e, 2)} onError={() => onTemplateClear(2)} onClear={() => onTemplateClear(2)}
                        headerTemplate={(e) => headerTemplate(e, 2)} itemTemplate={(e, f) => itemTemplate(files[1], f, 2)} emptyTemplate={(e) => emptyTemplate(e, 2)}
                        chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
                    <Divider align='left'>
                        <div className="inline-flex align-items-center">
                            <i className="pi pi-users"></i>
                            <b> {labelDocComp}</b>
                        </div>
                    </Divider>

                    <FileUpload ref={fileUploadRef2} name="doc_res" accept="image/*" maxFileSize={30000000}
                        onUpload={(e) => onTemplateUpload(e, 3)} onSelect={(e) => onTemplateSelect(e, 3)} onError={() => onTemplateClear(3)} onClear={() => onTemplateClear(3)}
                        headerTemplate={(e) => headerTemplate(e, 3)} itemTemplate={(e, f) => itemTemplate(files[2], f, 3)} emptyTemplate={(e) => emptyTemplate(e, 3)}
                        chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
                </div>
            }
            <br />
            <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'left', width: '50%' }}>
                    {props.activeIndex > 0 &&
                        <Button label='ANTERIOR' icon='pi pi-arrow-left' rounded onClick={() => verifyData({ index: props.activeIndex - 1 })} />
                    }
                </div>
                <div style={{ display: 'flex', justifyContent: 'right', width: '50%' }}>
                    {props.activeIndex < 3 ?
                        <Button label='PRÓXIMO' icon='pi pi-arrow-right' iconPos='right' rounded onClick={() => verifyData({ index: props.activeIndex + 1 })} />
                        :
                        <Button label={waiting ? '' : (props.pessoa.id ? 'ATUALIZAR' : 'CADASTRAR')} icon={waiting ? '' : 'pi pi-send'} iconPos='right' severity="success" rounded onClick={cadastrar} >{waiting && <ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth="8" />}</Button>
                    }
                </div>
            </div>
            {/* </div> */}
        </Dialog>
    );
}

export default CadastroPessoa;
