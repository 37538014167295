import '../../styles/pages/components/Sidebar-Left-Full.css'

type Props = {
    children?: React.ReactNode
}

function SidebarLeftFull({ children }: Props) {

    return (
        <div className='sidebar-left-full'>
            {children}
        </div>
    )
}

export default SidebarLeftFull;