import Cookies from 'js-cookie';
import { api } from './API'
export const login = async (cpf: string, password: string) => {
    try {
        const body = new FormData();
        body.append("cpf", cpf.replaceAll(".", "").replaceAll("-", ""));
        body.append("password", password);
        console.log(api.replace('aiba_adab/', '') + 'token/');
        const response = await fetch(api.replace('aiba_adab/', '') + 'token/',
            {
                method: "POST",
                mode: 'cors',
                body: body
            });

        return response;
    } catch (e: unknown) {
        return e;
    }
}

const fakeAuthProvider = {
    isAuthenticated: false,
    signin(callback: VoidFunction) {
        fakeAuthProvider.isAuthenticated = true;
        setTimeout(callback, 100); // fake async
    },
    signout(callback: VoidFunction) {
        fakeAuthProvider.isAuthenticated = false;
        setTimeout(callback, 100);
    }
};

function Logout(navigate: any) {
    // const navigate = useNavigate();
    // global.token = "";
    Cookies.remove('token')
    navigate("/");
}

// function getHeader() {
//     var headers = new Headers();
//     // headers.append('Authorization', 'JWT ' + global.token);
//     // headers.append('Authorization', 'Bearer ' + global.token);
//     const token = Cookies.get('token')
//     headers.append('Authorization', 'Bearer ' + token);
//     headers.append('Content-Type', 'application/json');
//     return headers;
// }

function getHeader(multipart?: any) {
    var headers = new Headers();
    // headers.append('Authorization', 'JWT ' + global.token);
    // headers.append('Authorization', 'Bearer ' + global.token);
    const token = Cookies.get('token')
    headers.append('Authorization', 'Bearer ' + token);
    if (!multipart)
        headers.append('Content-Type', 'application/json');
    return headers;
}

export { fakeAuthProvider, Logout, api, getHeader };