import { FarmVazioType } from '../pages/CadastroPropriedade';
import { getHeader } from './Authenticator';
import { PessoaType } from "../pages/CadastroPessoa";
import { VazioType } from "../pages/CadastroVazio";
import { confirmPopup } from 'primereact/confirmpopup'
import { Toast } from 'primereact/toast';
import * as messages from '../utils/messages'
import Cookies from 'js-cookie';

export const api: string = "https://api.damine.com.br/aiba_adab/";
export const getConfig = {
    method: "GET",
    // mode: 'cors',
    headers: getHeader()
}
export const deleteConfig = {
    method: "DELETE",
    // mode: 'cors',
    headers: getHeader()
}
type History = {
    id: number,
    action: number,
    user: number,
    date_time: string
}
export const getData = async (url: string, navigate: any, onError: Function) => {
    try {
        const response = await fetch(url, requestOptions('GET'));
        if (response.ok) {
            return await response.json()
        } else if (response.status === 401 || response.status === 400) {
            // onError(response)
            // navigate('/', { state: true })
        } else {
            onError(response)
            // navigate('/', { state: true })
            // console.log('Response', response)
        }
    } catch (e) {
        onError(e)
        // navigate('/', { state: true })
        // console.log('Error at  Get Data (' + url + '): ', e);
    }
    return null
}
export async function getPessoas(navigate: any, onError: Function) {
    const data: Array<PessoaType> = []

    const response = await getData(api + 'pessoas/', navigate, onError)
    if (response !== null)
        response.forEach((d: PessoaType) => {
            // console.log('Pessoa:', d)
            data.push(d)
        });
    return data;
}
export async function getPessoa(cpf_cnpj: string, navigate: any, onError: Function) {
    return await getData(api + 'pessoas/?cpf_cnpj=' + cpf_cnpj, navigate, onError)

    // try {
    //     const response = await fetch(api + 'pessoas/?cpf_cnpj=' + cpf_cnpj, requestOptions('GET'));
    //     if (response.ok) {
    //         const result: PessoaType = await response.json()
    //         return result
    //     }
    //     else if (response.status === 401)
    //         console.log(response)
    //     else
    //         console.log(response)
    // } catch (e) {
    //     console.log(e)
    // }
    // return null;
}
export async function newHistory() {
    const response = await fetch(api + 'history/',
        {
            method: "POST",
            mode: 'cors',
            headers: getHeader(),
            body: JSON.stringify({
                action: "1",
                user: "1",
            }),
        });
    const data: History = await response.json();
    return data;
}

export const removePessoa = async (pessoa: PessoaType, posFunction?: Function) => {
    await fetch(api + 'pessoas/' + pessoa.id, {
        method: "DELETE",
        mode: 'cors',
        headers: getHeader()
    });
    posFunction && posFunction()
    

}

export function RemoveFarm(event: any, farm: FarmVazioType, navigator?: any) {
    const remove = async () => {
        await fetch(api + 'farms/' + farm.id, {
            method: "DELETE",
            mode: 'cors',
            headers: getHeader()
        });
        console.log('Removido fazenda (' + farm.id + '): ' + farm.nome);
        console.log('Dono', farm.proprietario)
        alert('Propriedade ' + farm.nome + ' do proprietário ' + farm.proprietario.nome_razao + ' removida com sucesso!')
        navigator && navigator("/home");
    }
    const onAccept = () => {
        // Aqui você pode criar e retornar sua Promise
        const minhaPromise = new Promise((resolve, reject) => {
            remove()
            setTimeout(() => {
                // Resolvendo a Promise após a lógica assíncrona ser concluída
                resolve('Operação concluída com sucesso!');
            }, 1000);
        });

        // Pode ser útil retornar a Promise para o componente que chama o onAccept
        return minhaPromise;
    };

    const accept = () => {
        // toast.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
        alert('ok')
    }
    // const responsta = confirmDialog('bottom-right')
    confirmPopup({
        target: event.currentTarget,
        message: 'Do you want to delete this record?',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept: accept
        // accept: remove()
        // reject: console.log('')1
    });
    const resposta = window.confirm('Você tem certeza que deseja remover a fazenda ' + farm.nome + '?')
    if (resposta) remove();
}

export async function removePropriedade(propriedade: any, posFunction?: Function) {
    await fetch(api + 'propriedades/' + propriedade.id, {
        method: "DELETE",
        mode: 'cors',
        headers: getHeader()
    });
    console.log('Removido fazenda (' + propriedade.id + '): ' + propriedade.nome);
    console.log('Dono', propriedade.proprietario)
    alert('Propriedade ' + propriedade.nome + ' do proprietário ' + propriedade.proprietario.nome_razao + ' removida com sucesso!')
    posFunction && posFunction()
}

export const requestOptions = (metodo: string): RequestInit => {
    return {
        method: metodo,
        mode: 'cors',
        headers: getHeader()
    }
}

export async function getPropriedadesOfMunicipio(municipio: any, navigate: any, onError: Function) {
    const asd = await getData('https://api.damine.com.br/aiba_adab/propriedadesFull/?municipio=' + municipio, navigate, onError)
    console.log('Asd:', asd)
    return asd
    // try {
    //     const response = await fetch('https://api.damine.com.br/aiba_adab/propriedadesFull/?municipio=' + municipio, requestOptions('GET'));
    //     if (response.ok) {
    //         const results = await response.json()
    //         return results;
    //     }
    //     else
    //         console.log(response)
    // } catch (e) {
    //     console.log(e)
    // }
    // return []
}

export async function getPropriedades(navigate: any, onError: Function) {
    return await getData('https://api.damine.com.br/aiba_adab/propriedadesFull/', navigate, onError)
    // try {
    //     const response = await fetch('https://api.damine.com.br/aiba_adab/propriedadesFull/', requestOptions('GET'));
    //     if (response.ok) {
    //         const results = await response.json()
    //         return results;
    //     }
    //     else
    //         console.log(response)
    // } catch (e) {
    //     console.log(e)
    // }
    // return []
}
export async function getMunicipios(uf: string) {
    try {
        const response = await fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados/' + uf + '/municipios', requestOptions('GET'));
        if (response.ok) return await response.json()
        else console.log(response)
    } catch (e) {
        console.log(e)
    }
    return null
}
export const getMapas = async (uf: string) => {
    try {
        const response = await fetch('https://servicodados.ibge.gov.br/api/v3/malhas/estados/' + uf + '?intrarregiao=municipio&formato=application/vnd.geo+json')
        const data = await response.json()
        return data.features
    } catch (e) {
        console.log(e)
    }
    return null
}
export const loadingIbgeData = async (posFunction?: Function) => {
    try {
        // await new Promise( resolve => setTimeout(resolve, 3000) );

        const municipios = await getMunicipios('BA')
        Cookies.set('municipios', global.municipios)

        const mapas = await getMapas('BA')
        Cookies.set('mapas', mapas)

        global.municipios = []
        municipios.map((municipio: any) => {
            global.municipios.push({
                ...municipio,
                mapa: {
                    "type": "FeatureCollection",
                    "features": [
                        mapas.filter((mapa: any) => mapa.properties.codarea == municipio.id)[0]
                    ]
                }
            })
        })
    } catch (e) {
        console.log(e)
    }
    posFunction && posFunction(global.municipios)
}