import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GiFarmer } from 'react-icons/gi';
import { FaMapMarkedAlt } from 'react-icons/fa';
import '../styles/global.css';
import '../styles/pages/Home.css';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import 'primeicons/primeicons.css';
import { MdAgriculture, MdMap } from 'react-icons/md';
// import Titulo from './components/Title';
import Filter, { FilterType } from './components/Warnings';
import { FarmVazioType } from './CadastroPropriedade';
import * as L from 'leaflet'
import { createMapOnBahia, loadMunicipios, loadMunicipioss } from '../utils/mapas';
import { Dock } from 'primereact/dock';
import { MenuItem } from 'primereact/menuitem';
import { ReactComponent as OutIcon } from '../images/out.svg';
import { ReactComponent as EditIcon } from '../images/pencil-svgrepo-com.svg';
import { ReactComponent as AppsIcon } from '../images/calendar-smartphone-svgrepo-com.svg';
import { ReactComponent as ListIcon } from '../images/list2.svg';
import { Messages } from 'primereact/messages';
import { removePropriedade, getMunicipios, getPessoa, getPessoas, getPropriedades, loadingIbgeData, removePessoa } from '../auth/API';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import CadastroPessoa, { PessoaType, newPessoa } from './CadastroPessoa';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabPanel, TabView } from 'primereact/tabview';
import { Toast } from 'primereact/toast';
import * as messages from '../utils/messages';
import { Avatar } from 'primereact/avatar';
import Cookies from 'js-cookie';
import CustomToolbar from './components/CustomToolbar';
import { Badge } from 'primereact/badge';


export type WarningType = {
    id: string;
    farm: FarmVazioType;
    type: string;
    shapeImage: string;
    pointImage: string;
    report: string;
    shape: any
}
function Home() {
    const mapRef = useRef<any>(null);
    const msgs = useRef<any>(null);
    const toastBC = useRef<Toast>(null);
    const [mapa, setMapa] = useState<L.Map | null>(null);
    const navigate = useNavigate();

    const [pessoas, setPessoas] = useState<Array<any>>([])
    const [propriedades, setPropriedades] = useState<Array<any>>([])
    const [loading, setLoading] = useState(false)
    const [pessoa, setPessoa] = useState<PessoaType>(newPessoa)

    const [activeIndex, setActiveIndex] = useState(0)
    const fetchData = async () => {
        setPessoas(await getPessoas(navigate, () => messages.errorMessage(toastBC.current)))
        setPropriedades(await getPropriedades(navigate, () => messages.errorMessage(toastBC.current)))
    }

    useEffect(() => {
        try {
            if (!mapa) setMapa(createMapOnBahia(mapRef))
        } catch (e: any) {
            messages.message(toastBC.current, 'Erro no carregamento do mapa:' + e)
        }
        fetchData()
    }, []);

    const verifyCookies = () => {
        return global.municipios === undefined || global.municipios === null || global.municipios.length === 0
    }

    const carregarMapas = () => {
        if (verifyCookies())
            messages.message(toastBC.current, 'Erro na conexão com o IBGE, favor tente acessar mais tarde!')
        else
            loadMunicipioss(mapa, global.municipios)
    }
    const fetchMunicipiosEMapas = async () => {
        setLoading(true)
        if (!verifyCookies()) {
            carregarMapas()
            loadingIbgeData(() => { carregarMapas(); setLoading(false) })
        }
        else {
            await loadingIbgeData(carregarMapas)
            carregarMapas()
            setLoading(false)
        }
    }

    useEffect(() => {
        mapa && fetchMunicipiosEMapas()
    }, [mapa])


    const [showFrameAplicativos, setShowFrameAplicativos] = useState(false)
    const [showFrameCadastrar, setShowFrameCadastrar] = useState(false)
    const [showDialogList, setShowDialogList] = useState(false)
    const [showDialogCadastroPessoa, setShowDialogCadastroPessoa] = useState(false)

    const items: MenuItem[] = [
        { label: 'Pesquisar', icon: () => <ListIcon />, command: () => { setShowDialogList(true) } },
        { label: 'Aplicativos', icon: () => <AppsIcon />, command: () => { setShowFrameAplicativos(true) } },
        { label: 'Cadastrar', icon: () => <EditIcon />, command: () => { setShowFrameCadastrar(true) } },
        {
            label: 'Sair', icon: () => <OutIcon />, command: () => {
                Cookies.remove('token')
                navigate('/')
            }
        }
    ];

    const openMunicipio = (municipio: any) => (navigate('/municipio', { state: municipio }))
    const openPropriedade = (propriedade: any) => (navigate('/propriedade', { state: propriedade }))
    const openPessoa = (pessoa: PessoaType) => {
        console.log('Open pessoa:', pessoa)
        setPessoa(pessoa)
        setActiveIndex(0)
        setShowDialogCadastroPessoa(true)
    }
    const cadastrarNovaPessoa = () => {
        setPessoa(newPessoa())
        setActiveIndex(0)
        setShowDialogCadastroPessoa(true)
    }
    const tipoTemplate = (pessoa: PessoaType) => (<>{pessoa.cpf_cnpj.length > 14 ? <Avatar icon="pi pi-building" style={{ backgroundColor: '#9c27b0', color: '#ffffff' }} shape="circle" >{/*<Badge value="PJ" />*/}</Avatar> : <Avatar icon="pi pi-user" style={{ backgroundColor: '#2196F3', color: '#ffffff' }} shape="circle" />}</>)
    const optionsPessoas = (pessoa: PessoaType) => (<><Button icon="pi pi-user-edit" severity="secondary" onClick={() => openPessoa(pessoa)} /><Button icon="pi pi-trash" onClick={() => removerPessoa(pessoa)} severity="danger" /></>)
    const optionsMunicipios = (municipio: any) => (<><Button icon="pi pi-map-marker" severity="secondary" onClick={() => openMunicipio(municipio)} /></>)
    const optionsPropriedades = (propriedade: any) => (<><Button icon="pi pi-map-marker" severity="secondary" onClick={() => openPropriedade(propriedade)} /><Button icon="pi pi-trash" onClick={() => removerPropriedade(propriedade)} severity="danger" /></>)

    const toastDialogPesquisar = useRef<Toast | null>(null)

    const removerPessoa = (pessoa: PessoaType) => {
        const mensagemSucesso = () => {
            console.log('Removido fazenda (' + pessoa.id + '): ' + pessoa.nome_razao);
            messages.message(toastDialogPesquisar.current, pessoa.nome_razao + ' de id ' + pessoa.id + ' removido com sucesso!')
        }
        messages.confirmacao(toastDialogPesquisar.current, "Confirma a remoção de " + pessoa.nome_razao + '?', 'warn', () => { removePessoa(pessoa, mensagemSucesso) })
    }

    const removerPropriedade = (propriedade: any) => {
        const mensagemSucesso = () => {
            console.log('Removido fazenda (' + pessoa.id + '): ' + pessoa.nome_razao);
            messages.message(toastDialogPesquisar.current, propriedade.nome + ' de id ' + propriedade.id + ' removido com sucesso!')
        }
        messages.confirmacao(toastDialogPesquisar.current, "Confirma a remoção de " + propriedade.nome + '?', 'warn', () => { removePropriedade(propriedade, mensagemSucesso) })
    }
    const dataTableMunicipios = useRef<DataTable<any>>(null);
    const exportCSVMunicipios = () => {
        if (dataTableMunicipios.current !== null)
            dataTableMunicipios.current.exportCSV();
    };
    const dataTablePessoas = useRef<DataTable<any>>(null);
    const exportCSVPessoas = () => {
        if (dataTablePessoas.current !== null)
            dataTablePessoas.current.exportCSV();
    };
    const dataTablePropriedades = useRef<DataTable<any>>(null);
    const exportCSVPropriedades = () => {
        if (dataTablePropriedades.current !== null)
            dataTablePropriedades.current.exportCSV();
    };

    const paginatorRightMunicipios = <Button type="button" icon="pi pi-download" rounded data-pr-tooltip="CSV" onClick={exportCSVMunicipios} size='small' />;
    const paginatorRightPessoas = <Button type="button" icon="pi pi-download" rounded data-pr-tooltip="CSV" onClick={exportCSVPessoas} size='small' />;
    const paginatorRightPropriedades = <Button type="button" icon="pi pi-download" rounded data-pr-tooltip="CSV" onClick={exportCSVPropriedades} size='small' />;
    return (
        <div className='landing'>
            <CustomToolbar titulo={'MONITORA BAHIA'} loading={loading} />
            {/* <Filter type={FilterType.Vazio} map={mapa} /> */}
            <Dialog header="Pesquisa" visible={showDialogList} style={{ width: '840px' }} onHide={() => setShowDialogList(false)}
                draggable={false} resizable={false}>
                <Toast ref={toastDialogPesquisar} position='center'></Toast>
                <TabView>
                    <TabPanel header="Municípios" leftIcon="pi pi-map-marker mr-2">
                        <DataTable ref={dataTableMunicipios} value={global.municipios} stripedRows
                            paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
                            filterDisplay="row" sortField="nome" sortOrder={1}
                            selectionMode="single" onSelectionChange={(e) => openMunicipio(e.value)}
                            dataKey='id' tableStyle={{ minWidth: '40rem' }}
                            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                            currentPageReportTemplate="{first} até {last} de {totalRecords}"
                            paginatorRight={paginatorRightMunicipios}
                        >
                            <Column header='Nome' field="nome" filter />
                            <Column header='Microrregião' field="microrregiao.nome" filter filterField='microrregiao.nome' />
                            <Column header='Mesorregião' field="microrregiao.mesorregiao.nome" filter filterField='microrregiao.mesorregiao.nome' />
                            <Column header='Opções' body={optionsMunicipios} exportable={false} />
                        </DataTable>
                    </TabPanel>
                    <TabPanel header="Pessoas" leftIcon="pi pi-users mr-2">
                        <DataTable ref={dataTablePessoas} value={pessoas} stripedRows
                            paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
                            filterDisplay="row" sortField="nome_razao" sortOrder={1}
                            selectionMode="single" onSelectionChange={(e) => openPessoa(e.value)}
                            dataKey='id' tableStyle={{ minWidth: '40rem' }}
                            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                            currentPageReportTemplate="{first} até {last} de {totalRecords}"
                            paginatorRight={paginatorRightPessoas}
                            >
                            <Column header='Nome | Razão Social' field="nome_razao" filter />
                            <Column header='Tipo' body={tipoTemplate} />
                            <Column header='CPF | CNPJ' field="cpf_cnpj" filter />
                            <Column header='Cidade' field="cidade" filter />
                            <Column header='Opções' body={optionsPessoas} exportable={false} />
                        </DataTable>
                    </TabPanel>
                    <TabPanel header="Propriedades" leftIcon="pi pi-home mr-2">
                        <DataTable ref={dataTablePropriedades} value={propriedades} stripedRows
                            paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
                            filterDisplay="row" sortField="nome" sortOrder={1}
                            selectionMode="single" onSelectionChange={(e) => openPropriedade(e.value)}
                            dataKey='id' tableStyle={{ minWidth: '40rem' }}
                            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                            currentPageReportTemplate="{first} até {last} de {totalRecords}"
                            paginatorRight={paginatorRightPropriedades}
                            >
                            <Column header='Propriedade' field="nome" filter ></Column>
                            <Column header='Pessoa' field='proprietario.nome_razao' filterField='proprietario.nome_razao' filter></Column>
                            <Column header='Municipio' field='municipio.nome' filterField='municipio.nome' filter ></Column>
                            <Column header='Opções' body={optionsPropriedades} exportable={false}/>
                        </DataTable>
                    </TabPanel>´
                    <TabPanel header="Cultivos" leftIcon="pi pi-home mr-2"></TabPanel>
                </TabView>

            </Dialog>

            <CadastroPessoa function={setShowDialogCadastroPessoa} state={showDialogCadastroPessoa} pessoa={pessoa} setPessoa={setPessoa} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />

            <Dialog header="CADASTROS" visible={showFrameCadastrar} onHide={() => setShowFrameCadastrar(false)}>
                <TabView>
                    <TabPanel header="Terceiros" leftIcon="pi pi-users mr-2">
                        <p className="m-0">
                            <p><Button style={{ width: '100%' }} size="large" onClick={cadastrarNovaPessoa} icon={<GiFarmer />}>Pessoa</Button></p>
                            <p><Button style={{ width: '100%' }} size="large" onClick={() => navigate('/cadastroPropriedade')} icon={<MdAgriculture />}>Propriedade</Button></p>
                            <p><Button style={{ width: '100%' }} size="large" onClick={() => navigate('/cadastroCultivo')} icon={<FaMapMarkedAlt />}>Cultivo</Button></p>
                            {/* <p><Button style={{ width: '100%' }} size="large" onClick={() => messages.littleMessage(msgs.current)} icon={<FaMapMarkedAlt />}>Cultivo</Button></p> */}

                        </p>
                    </TabPanel>
                    <TabPanel header="Eventos" leftIcon="pi pi-calendar mr-2">
                        <p className="m-0">
                            <p><Button style={{ width: '100%' }} size="large" onClick={() => navigate('/cadastroVazio')} icon={<MdMap />}>Monitoramento</Button></p>
                            <p><Button style={{ width: '100%' }} size="large" onClick={() => messages.littleMessage(msgs.current)} icon='pi pi-calendar'>Antecipação</Button></p>
                        </p>
                    </TabPanel>
                    <TabPanel header="Organização" leftIcon="pi pi-building mr-2">
                        <p className="m-0">
                            <p><Button style={{ width: '100%' }} size="large" onClick={() => messages.littleMessage(msgs.current)} icon='pi pi-th-large'>Culturas</Button></p>
                        </p>
                    </TabPanel>
                </TabView>
                <Messages ref={msgs} style={{ position: 'fixed', bottom: 20, right: 20 }} />
            </Dialog>

            <Dialog header="APLICATIVOS" visible={showFrameAplicativos} maximizable style={{ width: '80vw' }} onHide={() => setShowFrameAplicativos(false)}>
                <TabView>
                    <TabPanel header="Vazio Sanitário" leftIcon="pi pi-flag mr-2">
                        <iframe
                            src={'https://jean.users.earthengine.app/view/detectorvaziovegetacao'}
                            title="Web Page Viewer"
                            width="100%"
                            style={{ minHeight: '500px', height: '100%' }}
                            frameBorder="0"
                        />
                    </TabPanel>
                    <TabPanel header="Cobertura Vegetal" leftIcon="pi pi-flag-fill mr-2">
                        <iframe
                            src={'https://jean.users.earthengine.app/view/app-histrico-de-cobertura-vegetal'}
                            title="Web Page Viewer"
                            width="100%"
                            style={{ minHeight: '500px', height: '100%' }}
                            frameBorder="0"
                        />
                    </TabPanel>
                </TabView>
            </Dialog>

            <div id='map' ref={mapRef} />

            <Toast ref={toastBC} position="center" onRemove={() => toastBC.current && messages.clear(toastBC.current)} />

            <Tooltip className="dark-tooltip" target=".dock-window .p-dock-action" my="center bottom-15" at="center top" showDelay={150} />
            <div className="dock-window">
                <Dock model={items} position={'bottom'} />
            </div>
        </div>
    );
}

export default Home;
