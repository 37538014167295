import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Farm from './components/PropriedadeCard';
import '../styles/global.css';
import '../styles/pages/Landing.css';
// import Titulo from './components/Title';
import SidebarLeft from './components/Sidebar-Left';
import { FiArrowLeft } from 'react-icons/fi';
import Filter, { FilterType } from './components/Warnings';
import { createMapOnBahia, loadMapaMunicipio, loadMunicipio, loadPropriedades } from '../utils/mapas';
import { getPropriedadesOfMunicipio } from '../auth/API';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ProgressBar } from 'primereact/progressbar';
import CustomToolbar from './components/CustomToolbar';
import { errorMessage } from '../utils/messages';
import { Toast } from 'primereact/toast';

function Municipio() {
    const location = useLocation()
    var municipio: any = location.state
    console.log('Município: ', municipio)
    if (!municipio.mapa) {
        municipio = global.municipios.find((m: any) => m.id === municipio.id)
    }
    const mapRef = useRef<any>(null);
    const [mapa, setMapa] = useState<L.Map | null>(null)
    const [propriedades, setPropriedades] = useState<Array<any> | null | undefined>(undefined)
    const navigate = useNavigate()

    useEffect(() => {
        if (!mapa) setMapa(createMapOnBahia(mapRef))
    }, []);

    const carregarPropriedades = async () => {
        setPropriedades(await getPropriedadesOfMunicipio(municipio.id, navigate, () => errorMessage(toast.current)))
        setLoading(false)
    }

    useEffect(() => {
        carregarPropriedades();
        loadMapaMunicipio(mapa, municipio)
    }, [mapa])

    useEffect(() => {
        console.log('Propriedades:', propriedades)
        if (propriedades)
            loadPropriedades(mapa, propriedades)
    }, [propriedades])

    const [loading, setLoading] = useState(true)
    const toast = useRef<Toast | null>(null)
    return (
        <div className='landing'>
            <Toast ref={toast} position='center' />
            <CustomToolbar titulo={'MUNICÍPIO ' + municipio.nome.toUpperCase()}
                loading={loading}

            />
            {/* <Filter type={FilterType.Farm} map={mapRef.current} /> */}
            <SidebarLeft>
                <Link to='/home' className='logout'><FiArrowLeft size={35} /></Link>
                {propriedades === undefined &&
                    <ProgressBar mode="indeterminate" color='black' style={{ height: '6px' }}></ProgressBar>
                }
                {propriedades && propriedades.map && propriedades.map((propriedade: any) =>
                    <Farm farm={propriedade} edit={false} />
                )}
                {((propriedades && propriedades.length === 0) || propriedades === null) &&
                    <>Nenhuma propriedade cadastrada neste muninípio</>
                }
            </SidebarLeft>
            <div id='map' ref={mapRef} />
        </div>
    );
}

export default Municipio;
