import '../../styles/pages/components/Sidebar-Right.css'

type Props = {
    children?: React.ReactNode
}

function SidebarRight({ children }: Props) {

    return (
        <div className='sidebar-right'>
            {children}
        </div>
    )
}

export default SidebarRight;