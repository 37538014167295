import Scrollbars from 'react-custom-scrollbars';
import { BiLogOut } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { Logout } from '../../auth/Authenticator';
import '../../styles/pages/components/Sidebar-Left.css'

type Props = {
    children: React.ReactNode
}

function SidebarLeft({ children }: Props) {
    const navigate = useNavigate();
    return (
        <div className='sidebar-left'>
            <div className='padding-15px'>
                <button onClick={e => Logout(navigate)} className='logout'><BiLogOut size={35} /></button>
            </div>
            <div className='padding-left-15px'>
                <Scrollbars>
                    {children}
                </Scrollbars>
            </div>
        </div>
    )
}

export default SidebarLeft;