import { useEffect, useRef, useState } from 'react';
import { MdPerson, MdLock, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { MapContainer, TileLayer } from "react-leaflet";
import InputMask from 'react-input-mask';
import '../styles/global.css';
import '../styles/pages/Login.css';
import adabLogo from '../images/adab.png';
import aibaLogo from '../images/aiba.png';
import { api, getPessoa } from '../auth/API';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as LogoEmbrapa } from '../images/embrapa.svg';
import { ReactComponent as MonitoraBahiaIcon } from '../images/monitorabahia.svg';
import Cookies from 'js-cookie';
import * as mapas from '../utils/mapas';
import { ProgressBar } from 'primereact/progressbar';
import { login } from '../auth/Authenticator';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { message } from '../utils/messages';
import { Password } from 'primereact/password';
import CadastroPessoa, { PessoaType, newPessoa } from './CadastroPessoa';

function Login() {
  const navigate = useNavigate();
  const location = useLocation()
  const token_invalid = location.state
  const mapRef = useRef<any>(null);
  const [cpf, setCPF] = useState("");
  const [password, setPassword] = useState("");
  const [errorCPF, setErrorCPF] = useState("");
  const [errorPass, setErrorPass] = useState(token_invalid ? 'Entre novamente!' : '');
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!token_invalid) {
      const token = Cookies.get('token')
      if (token) {
        const cpf = Cookies.get('cpf')
        if (cpf) {
          const user = getPessoa(cpf, navigate, () => { console.log('Erro na conexão com servidor!') })
          global.user = user
          navigate('/home')
        }
      }
    }
    try {
      const map = mapas.createMapOnBahia(mapRef, mapas.mapas.openStreetMap(), false, false, false)
      map.setView([-13.356751643055382, -38.01614326295], 6);
    } catch (e: any) {
      console.log('error:', e)
    }
  }, []);

  function changeShow(event: React.FormEvent) {
    event.preventDefault();
    setShow(!show);
  }

  const fetchData = async () => {
    var data = null
    try {
      setErrorCPF("");
      setErrorPass("");
      const response = await login(cpf, password) as Response;
      data = await response.json();

      if (data.token) {
        let token: string = "";
        token = data.token;
        Cookies.set('token', token)
        Cookies.set('cpf', cpf)
        // global.token = token;
        const user: any = getPessoa(cpf, navigate, () => { console.log('Erro na conexão com servidor!') })
        if (user)
          global.user = user
        navigate("/home");
      } else {
        setErrorCPF("");
        setErrorPass("");
        if (data.cpf) setErrorCPF("CPF em branco!");
        if (data.password) setErrorPass("Senha em branco!");
        if (data.non_field_errors) setErrorPass("Credenciais inválidas!");
      }

    } catch (e: unknown) {
      if (typeof e === "string") {
        // setError(e);
      } else if (e instanceof Error) {
        // setError(e.message);
      }
      console.log(e);
      setErrorPass("Erro na conexão com o servidor!")
    }
    setLoading(false)
    return data;
  }
  const [loading, setLoading] = useState(false)
  function logon(event: React.FormEvent) {
    event.preventDefault();
    //TESTE COOKIES REMOVIDO
    // console.log('Global municipios:', global.municipios)
    // global.municipios = [global.municipios[0], global.municipios[1], global.municipios[2]]
    // global.municipios = []
    // global.municipiosComMapas = undefined

    setLoading(true)
    fetchData();
  }
  const toast = useRef<Toast | null>(null)
  const [progress, setProgress] = useState(0)

  const cadastrar = () => {
    message(toast.current, "Módulo em desenvolvimento!")
  }
  const [showDialogCadastroPessoa, setShowDialogCadastroPessoa] = useState(false)
  const [pessoa, setPessoa] = useState<PessoaType>(newPessoa)
  const [activeIndex, setActiveIndex] = useState(0)
  const cadastrarNovaPessoa = () => {
    setPessoa(newPessoa())
    setActiveIndex(0)
    setShowDialogCadastroPessoa(true)
  }
  return (

    <div className='login'>
      <div className='opacidade'>
        <div className='container'>
          <Toast ref={toast} position='bottom-center' />
          <div className='div-central'>
            <div style={{ fontSize: '36px', fontFamily: 'Arial Black, Arial', color: 'black', marginBottom: '-10px' }}>MONITORA</div>
            <div style={{ fontSize: '36px', fontFamily: 'Arial Black, Arial', fontWeight: 12, color: 'black' }}><div style={{ paddingRight: '10px' }}>BAHIA</div>
              <MonitoraBahiaIcon style={{ width: '64px', height: '64px' }} /></div>
          </div>
          <div className="p-inputgroup flex-1">
            <span className="p-inputgroup-addon">
              <i className="pi pi-user"></i>
            </span>
            <InputMask id="ssn_input" value={cpf} onChange={(e: any) => setCPF(e.target.value)} mask='999.999.999-99' placeholder='Digite seu CPF' />
          </div>
          <br />
          {errorCPF && errorCPF.length > 0 && <div className="error">{errorCPF}</div>}
          <div className="p-inputgroup flex-1">
            <span className="p-inputgroup-addon">
              <i className="pi pi-key"></i>
            </span>
            <Password placeholder="Informe a senha de acesso" inputId="password" value={password} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)} feedback={false} toggleMask />
          </div>
          <br />
          <br />
          <br />
          {errorPass && errorPass.length > 0 && <div className="error">{errorPass}</div>}

          <Button type='submit' severity="secondary" icon="pi pi-sign-in" loading={loading} rounded onClick={logon}> ENTRAR</Button>
          <Button severity="secondary" icon="pi pi-user-plus" rounded onClick={cadastrar}>
            CADASTRAR
          </Button>

        </div>

        <div className='container'>
          
          <div className='form' >
            <CadastroPessoa function={setShowDialogCadastroPessoa} state={showDialogCadastroPessoa} pessoa={pessoa} setPessoa={setPessoa} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
            <Toast ref={toast} position='bottom-center'
            // content={( m: any ) => {
            //   <section className="flex p-3 gap-3 w-full bg-black-alpha-90 shadow-2 fadeindown" style={{ borderRadius: '10px' }}>
            //     <i className="pi pi-cloud-upload text-primary-500 text-2xl"></i>
            //     <div className="flex flex-column gap-3 w-full">
            //       <p className="m-0 font-semibold text-base text-white">{m.summary}</p>
            //       <p className="m-0 text-base text-700">{m.detail}</p>
            //       <div className="flex flex-column gap-2">
            //         <ProgressBar value={progress} showValue={false}></ProgressBar>
            //         <label className="text-right text-xs text-white">{progress}% uploaded...</label>
            //       </div>
            //       <div className="flex gap-3 mb-3">
            //         <Button label="Another Upload?" text className="p-0" onClick={() => {})}></Button>
            //         <Button label="Cancel" text className="text-white p-0" onClick={() => {}}></Button>
            //       </div>
            //     </div>
            //   </section>}}
            />
            <div className='div-central'>
              <div style={{ fontSize: '36px', fontFamily: 'Arial Black', color: 'black', marginBottom: '-10px' }}>MONITORA</div>
              <div style={{ fontSize: '36px', fontFamily: 'Arial Black', color: 'black' }}><div style={{ paddingRight: '10px' }}>BAHIA</div>
                <MonitoraBahiaIcon style={{ width: '64px', height: '64px' }} /></div>
            </div>
            <div className="loginInput" >
              <MdPerson />
              <InputMask mask='999.999.999-99' name='cpf' value={cpf} onChange={(e => setCPF(e.target.value))} placeholder="Digite seu CPF" />
            </div>
            {errorCPF && errorCPF.length > 0 && <div className="error">{errorCPF}</div>}
            <div className="loginInput">
              <MdLock />
              <input type={show ? 'text' : 'password'} name='password' value={password} onChange={(e => setPassword(e.target.value))} placeholder="Digite sua senha" />
              <div className="btn-show" onClick={changeShow}>{show ? <MdVisibilityOff /> : <MdVisibility />}</div>
            </div>
            {errorPass && errorPass.length > 0 && <div className="error">{errorPass}</div>}
            {/* {loading && } */}

            <button className="btn-form" type="submit" onClick={logon}>
              {loading ? <ProgressBar mode="indeterminate" color='white' style={{ backgroundColor: 'black', height: '6px', marginTop: '1px' }} /> : 'ENTRAR'}

            </button>
            {/* <Button className='btn-form' onClick={logon}>
              {loading && 
                <ProgressSpinner color='white' style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
              }
              {!loading && 'ENTRAR'}
            </Button> */}

            <button className="btn-form" onClick={() => message(toast.current, "Módulo em desenvolvimento!")}>
            {/* <button className="btn-form" onClick={() => cadastrarNovaPessoa}> */}
              CADASTRAR
            </button>
          </div>
          {/* TODO */}
          {/* <button className="btn">
            RECUPERAR SENHA
          </button> */}


          <div className='logos'>
            <LogoEmbrapa className='embrapaLogo' />
            <img src={adabLogo} className='adabLogo' />
            <img src={aibaLogo} className='aibaLogo' />
          </div>
        </div>
      </div>

      <div id='map' className='mapa-back' ref={mapRef} />

    </div >
  );
}

export default Login;