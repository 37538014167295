import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import React from "react";

import logo_damine from '../images/logo_damine.png'
import { ProgressSpinner } from "primereact/progressspinner"

export type types = 'info' | 'warn' | 'success' | 'error'

export const message = (toast: Toast | null, message = 'Módulo em desenvolvimento', type: types = 'info') => {
    if (toast !== null) {
        console.log(message)
        toast.clear();
        toast.show({
            severity: type,
            summary: message,
            sticky: true,
            content: (props: any) => (
                <div className="flex flex-column align-items-left" style={{ flex: '1' }}>
                    <div className="flex align-items-center gap-2" style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar image={logo_damine} shape="circle" />
                        <span className="font-bold text-900" style={{ paddingInlineStart: 5 }}><b>DAMINE</b></span>
                    </div>
                    <br></br>
                    <div className="font-medium text-lg my-3 text-900">{props.message.summary}</div>
                    <br></br>
                    <Button className="p-button-sm flex" label="Ok" severity='info' onClick={() => clear(toast)}></Button>
                </div>
            )
        });
    }
}

export const errorMessage = (toast: Toast | null, m = 'Erro de conexão com servidor!') => {
    message(toast, m, 'error')
};

export const clear = (toast: Toast) => {
    toast.clear();
};

export function littleMessage(msgs: any, mensagem = 'Módulo em desenvolvimento!', type: types = 'info') {
    if (msgs)
        msgs.show([{
            stick: true,
            severity: type,
            content: (
                <React.Fragment>
                    <Avatar image={logo_damine} shape="circle" />
                    <div className="ml-2" style={{ paddingInlineStart: 10 }}>{mensagem}</div>
                </React.Fragment>
            ),
        }])
}

export const confirmacao = (toast: Toast | null, message = 'Módulo em desenvolvimento', type: types = 'info', sim: Function) => {
    if (toast !== null) {
        var waiting = false
        toast.clear();
        toast.show({
            severity: type,
            summary: message,
            sticky: true,
            content: (props: any) => (
                <div className="flex flex-column align-items-left" style={{ flex: '1' }}>
                    <div className="flex align-items-center gap-2" style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar image={logo_damine} shape="circle" />
                        <span className="font-bold text-900" style={{ paddingInlineStart: 5 }}><b>DAMINE</b></span>
                    </div>
                    <br></br>
                    <div className="font-medium text-lg my-3 text-900">{props.message.summary}</div>
                    <br></br>
                    <Button className="p-button-sm flex" label={waiting ? '' : 'Sim'} severity='success' onClick={() => { sim(); waiting = true }}>{waiting && <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />}</Button>
                    <Button className="p-button-sm flex" label="Não" severity='danger' onClick={() => clear(toast)}></Button>
                </div>
            )
        });
    }
}