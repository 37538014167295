import React, { useState, ChangeEvent, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'primereact/resources/themes/lara-light-blue/theme.css'
import '../styles/global.css';
import '../styles/pages/CadastroFarm.css';
import { api, getHeader } from '../auth/Authenticator';
// import Titulo from './components/Title';
import Subtitulo from './components/Subtitle'
import '@geoman-io/leaflet-geoman-free'
import * as L from 'leaflet'
import { VazioType } from './CadastroVazio';
import { PessoaType } from './CadastroPessoa';
import * as mapas from '../utils/mapas';
import { Dropdown } from 'primereact/dropdown';
import { getMunicipios, getPessoas } from '../auth/API';
import * as turf from '@turf/turf'
import { Messages } from 'primereact/messages';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog, DialogProps } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Divider } from 'primereact/divider';
import { MenuItem } from 'primereact/menuitem';
import { Toast } from 'primereact/toast';
import CustomToolbar from './components/CustomToolbar';
import { Avatar } from 'primereact/avatar';
import { errorMessage } from '../utils/messages';
import { ProgressBar } from 'primereact/progressbar';

export type FarmType = {
    id: string;
    history: string;
    nome: string;
    proprietario: any;
    localidade: string;
    acesso: string;
    municipio: any;
    shp: string;
}

export type FarmVazioType = {
    id: string;
    history: string;
    nome: string;
    proprietario: any;
    localidade: string;
    acesso: string;
    vazio: VazioType;
    shp: string;
}

function newFarm(): FarmType {
    return {
        id: '',
        history: '',
        nome: '',
        proprietario: 0,
        localidade: '',
        acesso: '',
        municipio: 0,
        shp: ''
    };
}

function CadastroPropriedade(this: any) {
    const [fazenda, setFazenda] = useState<any>(newFarm());
    const [municipios, setMunicipios] = useState<Array<any>>([]);
    const [listaMunicipio, setListaMunicipios] = useState<Array<any>>([])
    const [listaPessoa, setListaPessoa] = useState<Array<any>>([])
    const [mapa, setMapa] = useState<L.Map | null>(null)
    const mapRef = useRef<any>(null);
    const [poligonoMunicipio, setPoligonoMunicipio] = useState<L.GeoJSON>()
    const msgs = useRef<any>(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [showDialog, setShowDialog] = useState(true)
    const [showDialogBusca, setShowDialogBusca] = useState(true)
    const [poligonos, setPoligonos] = useState<Array<any>>([])
    const [poligonosPropriedades, setPoligonosPropriedade] = useState<Array<any>>([])
    const [poligonosCultivo, setPoligonosCultivo] = useState<Array<any>>([])
    const [loadingPessoas, setLoadingPessoas] = useState(true)

    const carregarMunicipio = async (municipio: any) => {
        if (municipio) {
            poligonoMunicipio?.remove()
            const poligono = await mapas.loadMunicipio(mapa, municipio, mapas.estiloContorno)
            setPoligonoMunicipio(poligono as L.GeoJSON)
            poligono?.openPopup()
        }
    }

    function changeField(event: any) {
        const { value, name } = event.target;
        if (name === 'municipio')
            setErrorMunicipio('')
        else if (name === 'nome')
            setErrorNome('')
        else if (name === 'proprietario')
            setErrorProprietario('')

        console.log('ChangeField', name, value)
        setFazenda({ ...fazenda, [name]: value });

        if (name === 'municipio') {
            const municipio = municipios.find((m: any) => m.id === value.code)
            console.log('Municipio', municipio)
            carregarMunicipio(municipio)
        }
    }

    const verificarDiferenca = () => {

    }

    async function cadastrar(event: React.FormEvent) {
        event.preventDefault();
        var ok: any = null

        if (mapa) {
            poligonoMunicipio?.remove()
            ok = false
            mapa.eachLayer((layer: L.Layer) => {
                if (layer instanceof L.Polygon) {
                    console.log('Layer em analise', layer)
                    const turfMunicipio: any = mapas.layerToPolygon(poligonoMunicipio?.getLayers()[0])
                    const turfPropriedade = mapas.layerToPolygon(layer)
                    console.log('Poligono Vazio', poligonoMunicipio)
                    if (poligonoMunicipio) {
                        const difference = turf.difference(turfPropriedade, turfMunicipio)
                        if (difference) {
                            console.log('Propriedade com área fora do municipio')
                            layer.remove()
                            console.log('Difference', difference)
                            const intersect = turf.intersect(turfPropriedade, turfMunicipio)
                            if (intersect) {
                                const layerIntersect = L.geoJSON(intersect)
                                layerIntersect.bindPopup(`Área de ${mapas.areaInHaOf(layerIntersect)}`).addTo(mapa)
                            }
                            const layerDifference = L.geoJSON(difference, { style: { color: 'red' } })
                            mapa.flyToBounds(layerDifference.bindPopup(`Área de ${mapas.areaInHaOf(layerDifference)}`).addTo(mapa).getBounds())
                            msgs.current.show([{
                                stick: true,
                                severity: 'error',
                                content: (
                                    <React.Fragment>
                                        <div className="ml-2" style={{ paddingInlineEnd: 10 }}>Área fora de {fazenda.municipio.nome}</div>
                                        <Button label='Corrigir' severity='success' icon="pi pi-check" onClick={() => { layerDifference.remove(); ok = true }} />
                                    </React.Fragment>
                                ),
                            }])
                        } else {
                            console.log('Propriedade inclusa dentro do municipio')
                            ok = true
                        }
                    }
                }
            })

            // console.log('aqui')
            // Crie um Blob a partir do Uint8Array usando o construtor Blob
            // const shapefileBlob = new Blob([shapefileBuffer], { type: "application/zip" });
            // console.log('Blob', shapefileBlob)
            console.log('Ok3', ok)
            if (ok) {
                console.log('Tudo ok para o cadastro')
                // Prepare os dados a serem enviados para a API
                const formData = new FormData();
                formData.append('acesso', fazenda.acesso)
                formData.append('localidade', fazenda.localidade)
                formData.append('nome', fazenda.nome)
                formData.append('proprietario', fazenda.proprietario.code)
                formData.append('municipio', fazenda.municipio.code)
                formData.append('poligono', await mapas.getShapefileBuffer(mapa), fazenda.nome + '.zip'); // Arquivo Shapefile
                console.log('Form Data', formData)
                // Opções da solicitação
                const requestOptions = {
                    method: 'POST',
                    headers: getHeader(true),
                    body: formData,
                };

                try {
                    // Faça a solicitação POST usando a API Fetch
                    const response = await fetch(api + 'propriedades/', requestOptions)
                    const data = await response.json()

                    // Manipule a resposta da solicitação aqui
                    console.log('Resposta:', data);
                    // alert('Propriedade cadastrada com sucesso!')
                    newPropriedade(data.id)
                    // editableLayersRef.current?.clearLayers()

                } catch (error: any) {
                    // Lide com erros da solicitação aqui
                    console.error('Erro na data:', error);
                }
            } else if (ok === null) {
                console.log('Ok = null  ')
            }
            poligonoMunicipio?.addTo(mapa)
        }
    }

    const accept = () => {
        setFazenda(newFarm())
        mapas.clearMap(mapa)
    }

    const reject = () => {
        navigate('/home')
    }

    const newPropriedade = (id: any) => {
        confirmDialog({
            message: 'Propriedade cadastrado com sucesso de id ' + id + '!\nDeseja adicionar outra propriedade?',
            header: 'Cadastro de Propriedade',
            icon: 'pi pi-exclamation-triangle',
            accept,
            reject
        });
    };

    const loadPessoas = async () => {
        const pessoas = await getPessoas(navigate, () => errorMessage(toast.current))
        setListaPessoa(pessoas.map((v: PessoaType) => { return { name: v.nome_razao, code: v.id } }))
        setLoadingPessoas(false)
    }
    const loadMunicipios = async () => {
        const municipios = await getMunicipios('BA')
        setMunicipios(municipios)
        setListaMunicipios(municipios.map((m: any) => { return { name: m.nome, code: m.id } }))
        setLoading(false)
    }
    const showCadastro = () => setShowDialog(true)
    const showBusca = () => setShowDialogBusca(true)
    useEffect(() => {
        try {
            if (!mapa) {
                const map = mapas.createMapOnBahia(mapRef)
                const buttons: Array<mapas.ButtonMap> = [
                    {
                        icon: '<img width="18" src="https://www.svgrepo.com/show/372448/form.svg">',
                        func: showCadastro
                    },
                    {
                        icon: '<img width="18" src="https://www.svgrepo.com/show/513607/search.svg">',
                        func: showBusca
                    },
                ]
                mapas.editableMap(map, () => navigate('/home'), buttons, poligonos)
                map.on('moveend', function () {
                    setLoading(false)
                });
                map.on('zoom', function () {
                    setLoading(true)
                })
                map.on('zoomend', function () {
                    setLoading(false)
                })
                map.on('move', function () {
                    setLoading(true)
                });
                setMapa(map)
            }
        } catch (e: any) {
            console.log('error:', e)
        }

        loadPessoas()
        loadMunicipios()
    }, []);

    const [latitude, setLatitude] = useState(0)
    const [longitude, setLongitudde] = useState(0)

    const changeCoordinates = (event: ChangeEvent<any>) => {
        const { value, name } = event.target;
        console.log('Nome', name, 'Valor', value)
        if (name === 'latitude')
            setLatitude(value)
        else if (name === 'longitude')
            setLongitudde(value)
    }
    // const BOUNDS_STYLE = { weight: 1 }

    // function MinimapBounds({ parentMap, zoom }: { parentMap: any, zoom: number }): JSX.Element {
    //     const minimap = useMap()

    //     const onClick = useCallback(
    //         (e) => {
    //             parentMap.setView(e.latlng, parentMap.getZoom())
    //         },
    //         [parentMap],
    //     )
    //     useMapEvent('click', onClick)

    //     const [bounds, setBounds] = useState(parentMap.getBounds())
    //     const onChangeMap = useCallback(() => {
    //         setBounds(parentMap.getBounds())
    //         minimap.setView(parentMap.getCenter(), zoom)
    //     }, [minimap, parentMap, zoom])

    //     const handlers = useMemo(() => ({ move: onChangeMap, zoom: onChangeMap }), [])
    //     useEventHandlers({ instance: parentMap, context: parentMap }, handlers)

    //     return <Rectangle bounds={bounds} pathOptions={BOUNDS_STYLE} />
    // }



    // function MinimapControl({ className, position, zoom }: { className: string, position: string, zoom: number }) {
    //     const parentMap = useMap()
    //     const mapZoom = zoom || 0

    //     const minimap = useMemo(
    //         () => (
    //             <MapContainer
    //                 style={{ height: 120, width: 180 }}
    //                 center={parentMap.getCenter()}
    //                 zoom={mapZoom}
    //                 minZoom={3}
    //                 dragging={false}
    //                 doubleClickZoom={false}
    //                 scrollWheelZoom={false}
    //                 attributionControl={false}
    //                 zoomControl={false}>
    //                 <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
    //                 <MinimapBounds parentMap={parentMap} zoom={mapZoom} />

    //             </MapContainer>
    //         ),
    //         [],
    //     )
    //     const POSITION_CLASSES = {
    //         bottomleft: 'leaflet-bottom leaflet-left',
    //         bottomright: 'leaflet-bottom leaflet-right',
    //         topleft: 'leaflet-top leaflet-left',
    //         topright: 'leaflet-top leaflet-right',
    //     }
    //     const positionClass = (position && POSITION_CLASSES['bottomleft']) || POSITION_CLASSES.topright

    //     return (
    //         <div className={positionClass}>
    //             <div className="leaflet-control leaflet-bar">{minimap}</div>
    //         </div>
    //     )
    // }
    const [errorNome, setErrorNome] = useState('')
    const [errorProprietario, setErrorProprietario] = useState('')
    const [errorMunicipio, setErrorMunicipio] = useState('')
    const [activeIndex, setActiveIndex] = useState(0)
    const [position, setPosition] = useState<any>('center')
    const [modal, setModal] = useState<any>(true)
    const toast = useRef<Toast | null>(null);
    const verifyData = (event: any) => {
        console.log('Event Index:', event.index)
        console.log('Active Index:', activeIndex)
        if (event.index > activeIndex) {
            if (activeIndex === 0) {
                if (fazenda.nome.length < 1) {
                    toast.current && toast.current.show({ severity: 'error', summary: 'Dados cadastrais', detail: 'Nome não informado!' });
                    setErrorNome('* Campo obrigatório')
                    setActiveIndex(activeIndex)
                } else {
                    setErrorNome('')
                    setActiveIndex(event.index)
                    setModal(true)
                }
            } else if (activeIndex === 1) {
                if (!fazenda.proprietario) {
                    toast.current && toast.current.show({ severity: 'error', summary: 'Proprietário', detail: 'Proprietário não informado!' });
                    setErrorProprietario('* Campo obrigatório')
                    setActiveIndex(activeIndex)
                } else {
                    setActiveIndex(event.index)
                    setErrorProprietario('')
                    setPosition('top')
                    setModal(true)
                }

            } else if (activeIndex === 2) {
                if (!fazenda.municipio) {
                    toast.current && toast.current.show({ severity: 'error', summary: 'Município', detail: 'Município não informado!' });
                    setErrorMunicipio('* Campo obrigatório')
                    setActiveIndex(activeIndex)
                    setPosition('top')
                    setModal(true)
                } else {
                    toast && toast.current && toast.current.show({ severity: 'info', summary: 'Perímetro', detail: 'Desenhe o perímetro da propriedade no mapa!' });
                    setErrorMunicipio('')
                    setActiveIndex(event.index)
                    setPosition('top')
                    setModal(false)
                }

            } else if (activeIndex === 3) {
                // if (tipo === tipos[1] && props.pessoa.conj_resp === null) {
                //     toast.current && toast.current.show({ severity: 'error', summary: 'Relacionamento', detail: 'Responsável legal não informado!' });
                //     //     setActiveIndex(activeIndex)
                //     // } else {
                //     // toast.current.show({ severity: 'success', summary: 'Dados pessoais', detail: 'Informações válidas!' });
                //     setActiveIndex(event.index)
                // }
                // if (event.index > activeIndex)
                //     if (poligonos.length > 0 || poligonosPropriedades.length > 0) {
                //         setActiveIndex(event.index)
                //         console.log('Poligonos:', poligonos)
                //         poligonosPropriedades.push(poligonos.splice(0, poligonos.length))
                //         console.log('Poligonos:', poligonos)
                //         console.log('Poligonos Propriedades:', poligonosPropriedades)
                //         poligonosPropriedades.map((poligono) => poligono.setStyle({ ...mapas.estiloContorno, color: 'yellow' }));
                //     } else {
                //         setActiveIndex(activeIndex)
                //         errorMessage(toast.current, 'Desenho o perímetro da propriedade no mapa antes de continuar!')
                //     }
            // } else if (activeIndex === 4) {
            //     if(event.index < activeIndex){
            //         poligonosCultivo.push(poligonos.splice(0, poligonos.length))
            //         poligonosCultivo.map((poligono) => poligono.setStyle({color:'#FF6B6B'}))
            //     }
            }
        }
        else {
            setActiveIndex(event.index)
            setModal(event.index<3)
            setPosition(event.index < 2 ? 'center' : 'top')
            
        }

    }
    const items: MenuItem[] = [
        {
            label: 'Dados cadastrais',
            command: (event) => verifyData(event)
        },
        {
            label: 'Proprietário',
            command: verifyData
        },
        {
            label: 'Município',
            command: verifyData
        },
        {
            label: 'Perímetro',
            command: verifyData
        },
        {
            label: 'Cultivo',
            command: verifyData
        }
    ];
    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <Avatar icon='pi pi-file-edit' shape="circle"
            // style={{ backgroundColor: '#2196F3', color: '#ffffff' }}
            />
            <span className="font-bold white-space-nowrap" style={{ width: '100%' }}> Cadastro de Propriedade   </span>
            <span style={{ width: '100%' }} />
            <Button style={{ height: '32px' }} outlined={activeIndex !== 0} rounded label="1" onClick={() => verifyData({ index: 0 })} className="w-2rem h-2rem p-0" />
            <Button style={{ height: '32px' }} outlined={activeIndex !== 1} rounded label="2" onClick={() => verifyData({ index: 1 })} className="w-2rem h-2rem p-0" />
            <Button style={{ height: '32px' }} outlined={activeIndex !== 2} rounded label="3" onClick={() => verifyData({ index: 2 })} className="w-2rem h-2rem p-0" />
            <Button style={{ height: '32px' }} outlined={activeIndex !== 3} rounded label="4" onClick={() => verifyData({ index: 3 })} className="w-2rem h-2rem p-0" />
            {/* <Button style={{ height: '32px' }} outlined={activeIndex !== 4} rounded label="5" onClick={() => verifyData({ index: 4 })} className="w-2rem h-2rem p-0" /> */}
        </div>
    )

    return (
        <div className='CadastroFarm'>
            <ConfirmDialog />
            <Messages ref={msgs} style={{ position: 'fixed', bottom: 20, right: 280 }} />
            {/* <Titulo titulo={'CADASTRO DE PROPRIEDADE'} loading={loading} /> */}
            <CustomToolbar titulo={'CADASTRO DE PROPRIEDADE'} loading={loading} />
            <Subtitulo map={mapa}>
                <div className='label'>
                    LATITUDE:
                </div>
                <input
                    type='number'
                    className='field-subtitle'
                    name='latitude'
                    onChange={changeCoordinates}
                    placeholder='-12.356751'
                />
                <div className='label'>
                    LONGITUDE:
                    <input
                        type='number'
                        className='field-subtitle'
                        name='longitude'
                        onChange={changeCoordinates}
                        placeholder='-41.565716'
                    />
                    <button type="submit" style={{ color: 'black', backgroundColor: 'white', border: 'none', fontSize: '15px', padding: '2px', borderRadius: '10px' }} onClick={(e) => mapas.searchCoordinates(mapa, latitude, longitude)}>
                        PROCURAR
                    </button>
                </div>
            </Subtitulo>

            <Dialog
                // header="Cadastro de Propriedade"
                visible={showDialog}
                header={headerElement}
                maximizable style={{ width: '800px' }}
                onHide={() => {
                    setShowDialog(false)
                    // console.log('Tentou fechar')
                }
                }
                draggable={false} resizable={false} position={position} modal={modal}>

                <Toast ref={toast} position="bottom-right" />
                {/* <Steps model={items} activeIndex={activeIndex} onSelect={(e: any) => setActiveIndex(e.index)} readOnly={false} /> */}
                {activeIndex === 0 && <div>
                    <Divider align='left'>
                        <div className="inline-flex align-items-center">
                            <i className="pi pi-user"></i>
                            <b> Nome da Propriedade</b>
                        </div>
                    </Divider>
                    <div className="flex flex-column gap-2">
                        <div className="flex flex-column p-inputgroup flex-1">
                            <span className="p-inputgroup-addon"><i className="pi pi-user"></i></span>
                            <span className="p-float-label">
                                <InputText id="username" name='nome' value={fazenda.nome} onChange={changeField} />
                                <label htmlFor="username">Digite o nome completo da propriedade *</label>
                            </span>
                        </div>
                        {errorNome.length > 0 && <small style={{ color: 'red', paddingInlineStart: 5 }}>{errorNome}</small>}
                    </div>
                    <Divider align='left'>
                        <div className="inline-flex align-items-center">
                            <i className="pi pi-map-marker"></i>
                            <b> Localidade</b>
                        </div>
                    </Divider>
                    <div className="flex flex-column gap-2">
                        <div className="flex flex-column p-inputgroup flex-1">
                            <span className="p-inputgroup-addon"><i className="pi pi-map-marker"></i></span>
                            <span className="p-float-label">
                                <InputText id="username" name='localidade' value={fazenda.localidade} onChange={changeField} />
                                <label htmlFor="username">Descreva a localização da propriedade</label>
                            </span>
                        </div>
                    </div>
                    <Divider align='left'>
                        <div className="inline-flex align-items-center">
                            <i className="pi pi-car"></i>
                            <b> Acesso</b>
                        </div>
                    </Divider>
                    <div className="flex flex-column gap-2">
                        <div className="flex flex-column p-inputgroup flex-1">
                            <span className="p-inputgroup-addon"><i className="pi pi-car"></i></span>
                            <span className="p-float-label">
                                <InputText id="username" name='acesso' value={fazenda.acesso} onChange={changeField} />
                                <label htmlFor="username">Descreva o acesso da propriedade</label>
                            </span>
                        </div>
                    </div>
                </div>}
                {activeIndex === 1 && <div>
                    <Divider align='left'>
                        <div className="inline-flex align-items-center">
                            <i className="pi pi-user"></i>
                            <b> Proprietario</b>
                        </div>
                    </Divider>
                    <Dropdown name='proprietario' value={fazenda.proprietario} onChange={changeField}
                        options={listaPessoa} optionLabel="name" placeholder='Proprietário *' filter >{loadingPessoas && <ProgressBar mode='indeterminate' />}</Dropdown>
                    <small style={{ color: 'red', paddingInlineStart: 5 }}>{errorProprietario}</small>
                </div>}
                {activeIndex === 2 && <div>
                    <Divider align='left'>
                        <div className="inline-flex align-items-center">
                            <i className="pi pi-map"></i>
                            <b> Municipio</b>
                        </div>
                    </Divider>
                    <Dropdown name='municipio' value={fazenda.municipio} onChange={changeField}
                        options={listaMunicipio} optionLabel="name" placeholder='Município *' filter />
                    <small style={{ color: 'red', paddingInlineStart: 5 }}>{errorMunicipio}</small>
                </div>}
                {activeIndex === 3 && <div>
                    <><div>Desenhe o perímetro da propriedade no mapa, se preferir pode fechar esta janela!</div><br /></>
                </div>}
                {/* {activeIndex === 4 && <div>
                    <><div>Desenhe as áreas de cultivo da propriedade no mapa, se preferir pode fechar esta janela!</div><br /></>
                </div>} */}
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'left', width: '50%' }}>
                        {activeIndex > 0 &&
                            <Button label='ANTERIOR' icon='pi pi-arrow-left' rounded
                                onClick={() => verifyData({ index: activeIndex - 1 })}
                            />
                        }
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'right', width: '50%' }}>
                        {/* {activeIndex < 4 ? */}
                        {activeIndex < 3 ?
                            <Button label='PRÓXIMO' icon='pi pi-arrow-right' iconPos='right' rounded
                                onClick={() => verifyData({ index: activeIndex + 1 })}
                            />
                            :
                            <Button label={'CADASTRAR'} icon='pi pi-send' iconPos='right' severity="success" rounded onClick={cadastrar} />
                        }
                    </div>
                </div>
            </Dialog>
            <div id='map' ref={mapRef} />
        </div>
    );
}

export default CadastroPropriedade;
