
import React from 'react';
import { Toolbar } from 'primereact/toolbar';
import { Avatar } from 'primereact/avatar';
import { ReactComponent as MonitoraBahiaIcon } from '../../images/monitorabahia.svg';
import logo_damine from '../../images/logo_damine.png'
import { ProgressBar } from 'primereact/progressbar';
import '../../styles/pages/components/Title.css';

type Props = {
    titulo: string;
    loading?: boolean;
    progress?: number;
}

export default function CustomToolbar(props: Props) {
    const startContent = (
        <React.Fragment>
            <MonitoraBahiaIcon style={{ width: '32px', height: '32px', marginRight: '10px' }} />
        </React.Fragment>
    );

    const centerContent = (
        <div className="flex flex-wrap align-items-center gap-3">
            <span className="font-bold" style={{ color: 'white' }}>{props.titulo}</span>
        </div>
    );

    const endContent = (
        <React.Fragment>
            <div className="flex align-items-center gap-2">
                <Avatar image={logo_damine} shape="circle" />
                <span className="font-bold" style={{ color: 'white' }}>{global.user?.nome_razao}</span>
            </div>
        </React.Fragment>
    );

    return (
        <div className='titulo'>
            <div className="card">
                <Toolbar start={startContent} center={centerContent} end={endContent} className="bg-gray-900 shadow-2" style={{ borderRadius: '3rem', backgroundImage: 'linear-gradient(to right, var(--bluegray-500), var(--bluegray-800))' }} />
                {props.loading && <ProgressBar mode="indeterminate" color='#333333' style={{ marginTop: '0px', marginLeft: '35px', marginRight: '35px', height: '3px' }}></ProgressBar>}
                {props.progress && (props.progress<1) && <ProgressBar value={props.progress*100} color='#333333' style={{ marginTop: '-4px', marginLeft: '33px', marginRight: '33px', height: '3px' }}></ProgressBar>}
            </div>
        </div>
    );
}
