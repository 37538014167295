import { FiArrowLeft } from 'react-icons/fi';
import { FaTrash } from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import L from 'leaflet';
import Farm from './components/PropriedadeCard';
import '../styles/global.css';
// import '../styles/pages/Propriedade.css';
import { FarmVazioType } from './CadastroPropriedade'
// import Titulo from './components/Title';
import SidebarRight from './components/Sidebar-Right';
import SidebarLeft from './components/Sidebar-Left';
import { useEffect, useRef, useState } from 'react';
import { RemoveFarm, removePropriedade } from '../auth/API';
import { carregarZipFromURL, createMapOnBahia, popUpForPropriedade } from '../utils/mapas';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import CustomToolbar from './components/CustomToolbar';
import { Toast } from 'primereact/toast';
import { message } from '../utils/messages';

function Propriedade() {
    const mapRef = useRef<any>(null);
    const location = useLocation();
    const fazenda = location.state as any;
    const navigator = useNavigate();
    const [mapa, setMapa] = useState<L.Map>();

    console.log('Open Fazenda:', fazenda)

    useEffect(() => {
        try {
            !mapa && setMapa(createMapOnBahia(mapRef))
        } catch (e: any) {
            console.log('error:', e)
        }
    }, []);

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const loadFazenda = async () => {
            if (mapa) {
                if (fazenda.poligono) {
                    const poligonos = await carregarZipFromURL(fazenda.poligono, { color: 'cyan', fillColor: 'cyan', fillOpacity: 0.3 })
                    poligonos.eachLayer(poligono => {
                        if (poligono instanceof L.FeatureGroup) {
                            poligono.bindPopup(popUpForPropriedade(fazenda, poligono))
                            mapa.addLayer(poligono);
                            poligono.openPopup()
                        }
                        mapa.flyToBounds(poligonos.getBounds())
                    })
                }
            }
        }
        loadFazenda()
    }, [mapa]);

    const confirmRemove = () => {
        confirmDialog({
            message: 'Você tem certeza que deseja remover a fazenda ' + fazenda.nome + '?',
            header: 'Cadastro de Propriedade',
            icon: 'pi pi-exclamation-triangle',
            accept,
            reject
        });
    }

    const accept = async () => {
        const mensagemSucesso = () => {
            message(toast.current, 'Propriedade ' + fazenda.nome + ' identificada por ' + fazenda.id + ' foi removida com sucesso!')
        }
        await removePropriedade(fazenda, mensagemSucesso)
    }

    const reject = () => {
    }
    const toast = useRef<Toast | null>(null)

    return (
        <div className='Info'>
            <Toast ref={toast} position='center' />
            <ConfirmDialog />
            <CustomToolbar titulo={fazenda.nome.toUpperCase()} loading={loading} />
            <SidebarLeft>
                <Button className='logout' onClick={() => {
                    console.log('Fazenda', fazenda)
                    navigator('/municipio', { state: fazenda.municipio })
                }}><FiArrowLeft size={35} /></Button>
                {/* <Link to='/home' className='logout'><FiArrowLeft size={35} /></Link> */}
                <div className='module'>
                    <Farm farm={fazenda} edit={true} />
                    {/* <Button onClick={} /> */}
                </div>
            </SidebarLeft>

            <SidebarRight>
                <div className='fundo'>
                    <hr />
                    <button className='button-sidebar-right' onClick={e => confirmRemove()}><FaTrash /> REMOVER </button>
                </div>
            </SidebarRight>

            <div id='map' ref={mapRef} />
        </div >
    );
}


export default Propriedade;
