import L, { FeatureGroup, LatLngExpression } from 'leaflet';
import JSZip from 'jszip';
import { ChangeEvent, MutableRefObject } from 'react';
import * as shapefile from 'shapefile';
import { FarmType, FarmVazioType } from '../pages/CadastroPropriedade';
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import togeojson from 'togeojson';
import * as shp from '@mapbox/shp-write';
import * as turf from '@turf/turf'
import { ReactComponent as Marker } from '../images/map-marker-multiple-svgrepo-com.svg';
import { renderToString } from 'react-dom/server';
import Marker_PNG from '../images/map-marker-multiple-svgrepo-com.png'
import { WarningType } from '../pages/Home';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { useNavigate } from 'react-router-dom';
const att = '<a href="http://www.damine.com.br">Damine</a> &copy;';
export type ButtonMap = {
    icon: string
    func: Function
}
export const mapas =
{
    openStreetMap: () => {
        return L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: att
        })
    },
    openStreetMap_hot: () => {
        return L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: att
        })
    },
    openStreetMap_topo: () => {
        return L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: att
        })
    },
    cartocdn: () => {
        return L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: att
        })
    },
    googleSat: () => {
        return L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
            maxZoom: 20,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            attribution: att
        })
    },
    googleTerrain: () => {
        return L.tileLayer('https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}', {
            maxZoom: 20,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            attribution: att
        })
    },
    googleHybrid: () => {
        return L.tileLayer('https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
            maxZoom: 20,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            attribution: att
        })
    },
    googleStreets: () => {
        return L.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
            maxZoom: 20,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            attribution: att
        })
    },
    ows_topo: () => {
        return L.tileLayer.wms('http://ows.mundialis.de/services/service?', {
            layers: 'TOPO-WMS',
            attribution: att
        })
    },
    ows_topo_wms: () => {
        return L.tileLayer.wms('http://ows.mundialis.de/services/service?', {
            layers: 'TOPO-OSM-WMS',
            attribution: att
        })
    },
    ows_places: () => {
        return L.tileLayer.wms('http://ows.mundialis.de/services/service?', {
            layers: 'OSM-Overlay-WMS',
            attribution: att
        })
    },
    ows_topo_places: () => {
        return L.tileLayer.wms('http://ows.mundialis.de/services/service?', {
            layers: 'TOPO-WMS,OSM-Overlay-WMS',
            attribution: att
        })
    },
    ows_places_topo: () => {
        return L.tileLayer.wms('http://ows.mundialis.de/services/service?', {
            layers: 'OSM-Overlay-WMS,TOPO-WMS',
            attribution: att
        })
    },
    ows_srtm: () => {
        return L.tileLayer.wms('http://ows.mundialis.de/services/service?', {
            layers: 'SRTM30-Colored-Hillshade',
            attribution: att
        })
    },
    arcgis: () => {
        return L.tileLayer('https://{s}.maptiles.arcgis.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
            className: "basemap",
            maxNativeZoom: 17,
            maxZoom: 17,
            subdomains: ["clarity"],
            // attribution="Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community"
            attribution: att
        })
    },
    arcgis2: () => {
        return L.tileLayer('https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
            attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
        })
    },
    arcgis3: () => {
        return L.tileLayer('https://services.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places_Alternate/MapServer/tile/{z}/{y}/{x}', {
            attribution: 'Labels &copy; Esri &mdash; Source: Esri'
        })
    },
    nexrad: () => {
        return L.tileLayer.wms("http://mesonet.agron.iastate.edu/cgi-bin/wms/nexrad/n0r.cgi", {
            layers: 'nexrad-n0r-900913',
            format: 'image/png',
            transparent: true,
            attribution: "Weather data © 2012 IEM Nexrad"
        });
    },
    gebco: () => {
        return L.tileLayer.wms("https://wms.gebco.net/mapserv?", {
            layers: 'nexrad-n0r-900913',
            format: 'image/png',
            transparent: true,
            attribution: "Weather data © 2012 IEM Nexrad"
        });
    },
    esri: () => {
        return L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}', {
            attribution: 'Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ'
        })
    },
    stamenToner: () => {
        return L.tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}.png', {
            attribution: 'Map tiles by Stamen Design, under CC BY 3.0. Data by OpenStreetMap, under ODbL.'
        })
    },
    sentinel2: () => {
        return L.tileLayer.wms('https://services.sentinel-hub.com/ogc/wms/f3540572-844b-46b4-b89c-f855581e8b6c', {
            layers: '1_TRUE_COLOR', // Example layer
            format: 'image/png',
            maxZoom: 20,
            attribution: 'Sentinel Hub'
        })
    },
    sentinel: () => {
        const INSTANCE_ID = 'f3540572-844b-46b4-b89c-f855581e8b6c   ';
        const LAYER_ID = 'f3540572-844b-46b4-b89c-f855581e8b6c';
        const ACCESS_TOKEN = 'seu_access_token_aqui';

        return L.tileLayer(`https://services.sentinel-hub.com/ogc/wms/${INSTANCE_ID}?showLogo=false&` +
            `LAYER=${LAYER_ID}&` +
            // `BBOX=${map.getBounds().toBBoxString()}&` +
            `FORMAT=image/jpeg&` +
            // `WIDTH=${map.getSize().x}&` +   
            // `HEIGHT=${map.getSize().y}&` +
            `SRS=EPSG:4326&` +
            `TIME=2023-01-01/2023-01-31&` + // Ajuste este intervalo de tempo conforme necessário
            `MAXCC=20.0&` +
            `access_token=${ACCESS_TOKEN}`, {
            className: "basemap",
            maxNativeZoom: 17,
            maxZoom: 17,
            subdomains: ["clarity"],
            // attribution="Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community"
            attribution: att
        })
    }
};

export const basemap = L.control.layers({
    Lugares: mapas.ows_places(),
    Topografia: mapas.ows_topo(),
    'Lugares com topografia': mapas.ows_places_topo(),
    'Topografia com Lugares': mapas.ows_topo_places(),
    'Google Satélite': mapas.googleSat(),
    'Google Ruas': mapas.googleStreets(),
    'Google Relevo': mapas.googleTerrain(),
    'Google Híbrido': mapas.googleHybrid(),
    'ArcGis': mapas.arcgis(),
    'ArcGis2': mapas.arcgis2(),
    'ESRI': mapas.esri(),
    'Sentinel 2': mapas.sentinel2()
    // 'Stamen Toner': mapas.stamenToner(),
    // 'Sentinel': mapas.sentinel(),
    // 'NexRad': mapas.nexrad() //serviço de meteorologia
}, {
    'Arcgis 3': mapas.arcgis3(),
    'Sentinel 2': mapas.sentinel2()
    })

export const scale = L.control.scale({
    metric: true,
    imperial: false,
    position: 'topleft',

});
export const getWatermark = (mapa: L.Map | null) => {
    if (mapa) {
        // L.Control.Watermark = L.Control.extend({
        //     onAdd: function () {
        //         var img = L.DomUtil.create('img');

        //         img.src = '../../docs/images/logo.png';
        //         img.style.width = '200px';

        //         return img;
        //     },

        //     onRemove: function () {
        //         // Nothing to do here
        //     }
        // });

        // L.control.watermark = function (opts) {
        //     return new L.Control.Watermark(opts);
        // }

        // L.control.watermark({ position: 'bottomleft' }).addTo(mapa);
        // const image: any = new Image();
        // image.src = "https://example.com/watermark.png";

        // const overlay: any = new ImageOverlay<any>({
        //     position: [0, 0],
        //     image,
        //     zIndex: 100,
        // });

        // mapa.addLayer(overlay);
    }

}
export const createMapOnBahia = (mapRef: MutableRefObject<any>, mapa?: L.TileLayer, zoom?: boolean, layers?: boolean, escala?: boolean) => {
    // Criando instância do mapa pelo Leaflet
    const map = L.map(mapRef.current)
    // Posicionando mapa em posição central da Bahia e com Zoom para visualização integral do estado
    map.setView([-13.356751643055382, -42.06571614326295], 6);
    // Configurando o mapa de satélite híbrido do Google
    if (mapa === undefined) mapas.googleHybrid().addTo(map)
    else mapa.addTo(map)
    //Adicionando zoom
    if (zoom !== undefined && !zoom) map.zoomControl.remove()
    // Configurando escala métrica no canto superior direito
    if (escala === undefined || escala) scale.addTo(map)
    //Adicionando referencias de outros mapas
    if (layers === undefined || layers) basemap.addTo(map)

    return map;
}


// export const styledByWithIn = (mapa: L.Map | null, poligonVazio: any, e: any) => {
//     e.preventDefault()
//     // mapa.pm.setGlobalOptions({ pmIgnore: false });
//     if (mapa)
//         if (e.layer && e.layer.pm) {
//             const poligono = e.layer;
//             poligono.pm.enable();

//             // editableLayers.addLayer(poligono)
//             console.log(`object created: ${poligono.pm.getShape()}`);

//             mapa.pm.getGeomanLayers(true)
//             // console.log('Geoman Layers:', mapa.pm.getGeomanLayers())
//             if (mapa.pm.getGeomanLayers) {
//                 const layers: Array<L.Layer> | L.FeatureGroup = mapa.pm.getGeomanLayers()
//                 if (layers instanceof Array) {
//                     const last = layers[layers.length - 1]
//                     const index = layers.length === 0 ? 1 : layers.length - 1
//                     last.bindPopup(`Área ${index} com ${areaInHaOf(last)}`)
//                     last.openPopup()
//                     const poligonoIn = (last as L.Polygon).toGeoJSON()
//                     console.log(poligonVazio)
//                     if (poligonVazio) {
//                         const poligonoOut = (poligonVazio.getLayers()[0] as L.Polygon).toGeoJSON()
//                         const dentro = turf.booleanWithin(poligonoIn, poligonoOut)
//                         console.log('Dentro:', dentro)
//                         if (dentro)
//                             (last as L.Polygon).setStyle({ color: 'cyan' })
//                         else
//                             (last as L.Polygon).setStyle({ color: 'red' })
//                     }
//                 }
//             }
//             poligono.on("pm:edit", (e: any) => {
//                 console.log("object edited: ", e);
//             });
//         }
// }

// export const addControl = (mapa: L.Map | null ) => {
//     const Control = L.Control.extend({
//         onAdd: function(map: any) {
//           var el = L.DomUtil.create('div', 'leaflet-bar my-control');

//           el.innerHTML = 'My Control';

//           return el;
//         },

//         onRemove: function(map: any) {
//           // Nothing to do here
//         }
//       });

//       const control = function(opts: any) {
//         return Control(opts);
//       }

//       L.control.myControl({
//         position: 'topright'
//       }).addTo(mapa);
// }

export const saveKML = (mapa: any) => {
    if (mapa) {
        circleToPolygon(mapa)
        var kmlData = '<?xml version="1.0" encoding="UTF-8"?>' +
            '<kml xmlns="http://www.opengis.net/kml/2.2">'
        console.log('asd')
        mapa.eachLayer((poligono: any) => {
            console.log('asd')
            if (poligono instanceof L.Polygon) {
                kmlData += '<Placemark><name>Polígono</name><Polygon><outerBoundaryIs><LinearRing><coordinates>';
                poligono.getLatLngs().forEach((latlng: any) => {
                    console.log('Latlon', latlng)
                    latlng.forEach((coord: any, index: number) => {
                        kmlData += coord.lng + ',' + coord.lat + ',0 ';
                    })
                });

                kmlData += '</coordinates></LinearRing></outerBoundaryIs></Polygon></Placemark></kml>';


            }
        });
        // Criar um blob com os dados KML
        var blob = new Blob([kmlData], { type: 'application/vnd.google-earth.kml+xml' });

        // Criar um link para download do arquivo KML
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'poligono.kml';

        // Adicionar o link ao documento e clicá-lo automaticamente para iniciar o download
        document.body.appendChild(link);
        link.click();

        // Remover o link do documento
        document.body.removeChild(link);
    }
}
export const editableMap = (mapa: L.Map | null, back: Function, buttons?: Array<ButtonMap>, poligonos?: Array<any>) => {
    if (mapa) {

        // const editableLayers = new L.FeatureGroup();
        // mapa.addLayer(editableLayers);

        mapa.pm.addControls({
            position: "bottomright",
            drawMarker: false,
            drawCircleMarker: false,
            drawCircle: true,
            drawPolyline: false,
            drawRectangle: true,
            rotateMode: true,
            drawText: false,
        });

        var customControl = L.Control.extend({
            options: {
                position: 'bottomright'
            },

            onAdd: function (map: any) {
                var container = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom');

                var input = L.DomUtil.create('input', 'hidden', container);
                input.type = 'file';
                input.onchange = function (e: any) {
                    openFile(e, mapa)
                };

                const contentButtons = [
                    {
                        icon: '<img width="18" src="https://www.svgrepo.com/show/376086/import.svg">',
                        func: function () { input.click() }
                    },
                    {
                        icon: '<img width="16" src="https://www.svgrepo.com/show/449207/save-filled.svg">',
                        func: function () { salvarShapefile(mapa) }
                    },
                    {
                        icon: '<img width="22" src="https://www.svgrepo.com/show/158088/kml-file-format-interface.svg">',
                        func: function () { saveKML(mapa) }
                    },
                    {
                        icon: '<img width="18" src="https://www.svgrepo.com/show/51246/home-map-location.svg">',
                        func: function () { goHome(mapa) }
                    }
                ]

                contentButtons.map((content) => {
                    var button: HTMLButtonElement = L.DomUtil.create('button', '', container)
                    button.innerHTML = content.icon
                    button.onclick = content.func
                    button.style.width = '32px'
                    button.style.height = '32px'
                    button.style.display = 'flex'
                    button.style.alignItems = 'center'
                    button.style.justifyContent = 'center'
                })

                L.DomEvent.disableClickPropagation(container);

                return container;
            }
        });

        mapa.addControl(new customControl());


        var backControl = L.Control.extend({
            options: {
                position: 'topleft'
            },

            onAdd: function (map: any) {
                var container = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom');

                var input = L.DomUtil.create('input', 'hidden', container);
                input.type = 'file';
                input.onchange = function (e: any) {
                    openFile(e, mapa)
                };


                var button: HTMLButtonElement = L.DomUtil.create('button', '', container)
                button.innerHTML = '<img width="22" src="https://www.svgrepo.com/show/500472/back.svg">'
                button.onclick = function () { back() }
                button.style.width = '32px'
                button.style.height = '32px'
                button.style.display = 'flex'
                button.style.alignItems = 'center'
                button.style.justifyContent = 'center'


                L.DomEvent.disableClickPropagation(container);

                return container;
            }
        });

        mapa.addControl(new backControl());

        if (buttons) {
            var customControl2 = L.Control.extend({
                options: {
                    position: 'topright'
                },

                onAdd: function (map: any) {
                    var container = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom');

                    var input = L.DomUtil.create('input', 'hidden', container);
                    input.type = 'file';
                    input.onchange = function (e: any) {
                        openFile(e, mapa)
                    };

                    buttons.map((content) => {
                        var button: HTMLButtonElement = L.DomUtil.create('button', '', container)
                        button.innerHTML = content.icon
                        button.onclick = function () { content.func() }
                        button.style.width = '32px'
                        button.style.height = '32px'
                        button.style.display = 'flex'
                        button.style.alignItems = 'center'
                        button.style.justifyContent = 'center'
                    })

                    L.DomEvent.disableClickPropagation(container);

                    return container;
                }
            });

            mapa.addControl(new customControl2());
        }
        // var div = document.createElement('div');
        // div.innerHTML = '<ConfirmPopup />';

        // mapa.getContainer().appendChild(div)
        // mapa.pm.setPathOptions({
        // color: 'blue',
        // fillColor: 'blue',
        // fillOpacity: 0.2,
        // });

        // mapa.pm.setGlobalOptions({ pmIgnore: false });
        mapa.on('pm:create', (e: any) => {
            // mapa.on(L.Draw.Event.CREATED, (e: any) => {
            if (e.layer && e.layer.pm) {
                const poligono = e.layer;
                poligono.pm.enable();

                console.log('Poligonos:', poligonos)
                console.log('Poligono:', poligono)
                poligonos?.push(poligono)
                console.log('Poligonos:', poligonos)

                // editableLayers.addLayer(poligono)
                console.log(`object created: ${poligono.pm.getShape()}`);

                // mapa.pm.getGeomanLayers(true)
                // console.log('Geoman Layers:', mapa.pm.getGeomanLayers())
                if (mapa.pm.getGeomanLayers) {
                    const layers: Array<L.Layer> | FeatureGroup = mapa.pm.getGeomanLayers()
                    if (layers instanceof Array) {
                        const last = layers[layers.length - 1]
                        const index = layers.length === 0 ? 1 : layers.length - 1
                        last.bindPopup(`Área ${index} com ${areaInHaOf(last)}`)
                        last.openPopup()
                    }
                }
                // poligono.on("pm:edit", (e: any) => {
                // poligono.on(L.Draw.Event.EDITED, (e: any) => {
                //     console.log("object edited: ", e);
                // });
            }
        })

        mapa.on("pm:remove", (e: any) => {
            console.log("object removed");

        });

        // leafletContainer.on("pm:edit", (e: any) => {
        //   console.log("object edited");
        //   console.log(e);

        // });

        // return () => {
        //     mapa.pm.removeControls();
        //     // mapa.pm.setGlobalOptions({ pmIgnore: true });
        // };
        // return editableLayers;
    }
    // return null;
}
export const carregarZipFromURL2 = async (url: string, estilo: any) => {
    // const poligonos = L.featureGroup()
    // const poligonos = new L.FeatureGroup()
    const poligonos: Array<L.GeoJSON> = []
    if (url) {
        const response = await fetch(url)
        if (response.ok) {
            const file = await response.arrayBuffer();
            const zipFile = await new JSZip().loadAsync(file);

            const shpFile = zipFile.file(/\.shp$/)[0];
            // const shxFile = zipFile.file(/\.shx$/)[0];

            // if (shpFile && shxFile) {
            if (shpFile) {
                const shpArrayBuffer = await shpFile.async('arraybuffer');
                // const shxArrayBuffer = await shxFile.async('arraybuffer');
                // const geojson = shpjs.combine([shpArrayBuffer, shxArrayBuffer]);

                // Realize qualquer processamento adicional necessário com o geojson carregado
                // console.log('GeoJSON carregado:', geojson);
                const buffer = await shapefile.openShp(shpArrayBuffer)
                var result
                do {
                    result = await buffer.read();
                    if (!result.done) {
                        // poligonos.addLayer(L.geoJSON(result.value, { style: estilo }))
                        poligonos.push(L.geoJSON(result.value, { style: estilo }))
                    }
                } while (!result.done)
            } else {
                console.error('Arquivos .shp e .shx não encontrados no arquivo ZIP.');
            }
        };
    }
    return poligonos;
}
export const carregarZipFromURL = async (url: string, estilo?: any) => {
    // const poligonos = L.featureGroup()
    const poligonos = new L.FeatureGroup()
    // const poligonos: Array<L.GeoJSON> = []
    if (url) {
        const response = await fetch(url)
        if (response.ok) {
            const file = await response.arrayBuffer();
            const zipFile = await new JSZip().loadAsync(file);

            const shpFile = zipFile.file(/\.shp$/)[0];
            // const shxFile = zipFile.file(/\.shx$/)[0];

            // if (shpFile && shxFile) {
            if (shpFile) {
                const shpArrayBuffer = await shpFile.async('arraybuffer');
                // const shxArrayBuffer = await shxFile.async('arraybuffer');
                // const geojson = shpjs.combine([shpArrayBuffer, shxArrayBuffer]);

                // Realize qualquer processamento adicional necessário com o geojson carregado
                // console.log('GeoJSON carregado:', geojson);
                const buffer = await shapefile.openShp(shpArrayBuffer)
                var result
                do {
                    result = await buffer.read();
                    if (!result.done) {
                        if (estilo)
                            poligonos.addLayer(L.geoJSON(result.value, { style: estilo }))
                        else
                            poligonos.addLayer(L.geoJSON(result.value))
                    }
                } while (!result.done)
            } else {
                console.error('Arquivos .shp e .shx não encontrados no arquivo ZIP.');
            }
        };
    }
    return poligonos;
}
export const clearMap = (mapa: L.Map | null) => {
    mapa?.eachLayer((layer: L.Layer) => {
        if (layer instanceof L.Polygon) layer.remove()
        if (layer instanceof L.Polyline) layer.remove()
        if (layer instanceof L.Marker) layer.remove()
        if (layer instanceof L.Circle) layer.remove()
        if (layer instanceof L.CircleMarker) layer.remove()
        if (layer instanceof L.FeatureGroup) layer.remove()
    })
}
// export const removeAllPolylines = (mapa: L.Map | null) => {
//     mapa?.eachLayer((layer: L.Layer) => {
//         if (layer instanceof L.Polygon) console.log('is poligono')
//         else if (layer instanceof L.Polyline) layer.remove()
//     })
// }
// export const carregarZipFromURLLikePolyline = async (url: string, estilo: any) => {
//     const poligonos = await carregarZipFromURL(url, estilo)
//     const polylines = L.featureGroup()
//     poligonos.eachLayer((poligono: any) => {
//         // polylines.addLayer(L.polyline(poligono.getLayers()[0]._latlngs, estilo))
//         polylines.addLayer(L.polyline([poligono.getLayers()[0].getLatLngs(), poligono.getLayers()[0].getLatLngs()[0]], estilo))
//     })
//     return polylines
// }

//TESTAR https://github.com/gabriel-russo/Leaflet.BetterFileLayer
export const carregarShpFromURL = (url: string, mapa: any, estilo: any, popUp: string) => {
    if (url) {
        shapefile.openShp(url).then((a) => {
            a.read().then(function log(result): any {
                if (result.done) return;
                L.geoJSON(result.value, { style: estilo }).bindPopup(popUp).setZIndex(10).addTo(mapa);
                return a.read().then(log);
            })
        });
    }
}
export const circleToPolygon = (mapa: L.Map) => {
    mapa.eachLayer((layer: any) => {
        // Se a forma desenhada for um círculo, converta-a em um polígono
        if (layer instanceof L.Circle) {
            const polygon = L.PM.Utils.circleToPolygon(layer, 60)
            mapa.addLayer(polygon);
            mapa.removeLayer(layer);
        }
    })
}

export const searchCoordinates = (mapa: L.Map | null, latitude: any, longitude: any) => {
    const userLocation: LatLngExpression = [latitude, longitude];
    mapa?.flyTo(userLocation, 20, {
        duration: 2,
        easeLinearity: 0.25
    })
    mapa?.addLayer(L.marker(userLocation).bindPopup('Sua localização é:<br>Latitude: ' + latitude + '<br>Longitude: ' + longitude).openPopup());
}

export const layerToPolygon = (layer: L.Layer | any) => {
    const latlngs = layer.getLatLngs()[0]
    // console.log('LatLngs', latlngs)
    var coordinates = latlngs.map((latlng: any) => [latlng.lng, latlng.lat])
    coordinates = [...coordinates, coordinates[0]]
    // console.log('Coordinates', coordinates)
    return turf.polygon([coordinates])
}
export const centro = (layer: L.Layer | any) => {
    try {
        return turf.centerOfMass(layerToPolygon(layer))
    } catch (e) {
        // console.log('Erro no calculo de centro 1:', e)
        try {
            return turf.centerOfMass(layerToPolygon(layer.getLayers()[0]))
        } catch (e) {
            // console.log('Erro no calculo de centro 2:', e)
        }
    }
    return null
}
export const perimetro = (layer: L.Layer | any) => {
    try {
        return turf.length(turf.polygonToLine(layerToPolygon(layer)))
    } catch (e) {
        // console.log('Erro no calculo de perimetro 1:', e)
        try {
            return turf.length(turf.polygonToLine(layerToPolygon(layer.getLayers()[0])))
        } catch (e) {
            // console.log('Erro no calculo de perimetro 2:', e)
        }
    }
    return 0
}
export const perimetroInKm = (layer: L.Layer | any) => {
    return perimetro(layer).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' km'
}
export const area = (layer: L.Layer | any) => {
    var area = 0
    if (layer instanceof L.Circle) {
        const radius = layer.getRadius();
        area = Math.PI * Math.pow(radius, 2);
    } else {
        try {
            area = turf.area(layerToPolygon(layer))
        } catch (e) {
            // console.log('Erro no calculo de area 1:', e)
            try {
                area = turf.area(layerToPolygon(layer.getLayers()[0]))
            } catch (e) {
                // console.log('Erro no calculo de area 2:', e)
            }
        }
    }
    return area
}
export const areaInM2Of = (layer: L.Layer | any) => {
    return area(layer).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' m²'
}
export const areaInHaOf = (layer: L.Layer | any) => {
    return (area(layer) / 1e4).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' ha'
}
export const areaInKm2Of = (layer: L.Layer | any) => {
    return (area(layer) / 1e6).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' km²'
}
export const coord2Text = (coord: number) => {
    return coord.toLocaleString('pt-BR', { minimumFractionDigits: 6, maximumFractionDigits: 6 })
}

export const popUpForPropriedade = (propriedade: FarmType | FarmVazioType, poligono: any) => {
    console.log(propriedade)
    return '<h1>' + propriedade.nome.toUpperCase() + '<div style="-moz-transform: scaleX(-1);-webkit-transform: scaleX(-1);-o-transform: scaleX(-1);transform: scaleX(-1);-ms-filter: fliph;filter: fliph;">🚜</div></h1>' +
        '<hr>' +
        '<b>Proprietário: </b>' + propriedade.proprietario?.nome_razao + '<br />' +
        '<b>Localidade: </b>' + propriedade.localidade + '<br />' +
        '<b>Acesso: </b>' + propriedade.acesso + '<br />' +
        '<b>Área aprox.: </b>' + areaInHaOf(poligono)
}
export const popUpForWarning = (warning: WarningType, poligono: any) => {
    const a = '<p style="margin-top:5px;padding-top:0px;margin-bottom:5px;">' +
        '<b>⚠️</b><br />' +
        '<b>Detecção de ' + (warning.type === 's' ? 'soja! 🌾' : (warning.type === 'c' ? 'algodão! ☘️' : warning.type)) + '</b><br>' +
        'Relatório: <a href="' + warning.report + '" target="_blank">link do PDF</a>' +
        '</p>' +
        '<table>' +
        '<tr>' +
        '<th>Imagem via satélite</th>' +
        '<th>Detecção processada</th>' +
        '</tr>' +
        '<tr>' +
        '<td>' +
        '<img src=' + warning.shapeImage + ' height="100" />' +
        '</td>' +
        '<td>' +
        '<img src=' + warning.pointImage + ' height="100" />' +
        '</td>' +
        '</tr>' +
        '</table>'
    return a

}
export const popUpForMunicipio = (municipio: any, poligono: any) => {
    const center: any = centro(poligono)?.geometry.coordinates
    return '<h1 style="margin:0px">' + municipio.nome + '<img height="20px" src="https://servicodados.ibge.gov.br/api/v3/malhas/municipios/' + municipio.id + '" />' + '</h1>' +
        '<b>Código: </b>' + municipio.id +
        '<hr><b>Região Imediata: </b>' + municipio["regiao-imediata"].nome + ' (' + municipio["regiao-imediata"].id + ') ' +
        '<img height="12px" src="https://servicodados.ibge.gov.br/api/v3/malhas/regioes-imediatas/' + municipio["regiao-imediata"].id + '" />' +
        '<br><b>Região Intermediária: </b>' + municipio["regiao-imediata"]["regiao-intermediaria"].nome + ' (' + municipio["regiao-imediata"]["regiao-intermediaria"].id + ') ' +
        '<img height="12px" src="https://servicodados.ibge.gov.br/api/v3/malhas/regioes-intermediarias/' + municipio["regiao-imediata"]["regiao-intermediaria"].id + '" />' +
        '<hr><b>Microrregião: </b>' + municipio.microrregiao.nome + ' (' + municipio.microrregiao.id + ') ' +
        '<img height="12px" src="https://servicodados.ibge.gov.br/api/v3/malhas/microrregioes/' + municipio.microrregiao.id + '" />' +
        '<br><b>Mesorregião: </b>' + municipio.microrregiao.mesorregiao.nome + ' (' + municipio.microrregiao.mesorregiao.id + ') ' +
        '<img height="12px" src="https://servicodados.ibge.gov.br/api/v3/malhas/mesorregioes/' + municipio.microrregiao.mesorregiao.id + '" />' +
        '<hr><b>Estado: </b>' + municipio.microrregiao.mesorregiao.UF.nome + ' - ' + municipio.microrregiao.mesorregiao.UF.sigla + ' (' + municipio.microrregiao.mesorregiao.UF.id + ') ' +
        '<img height="12px" src="https://servicodados.ibge.gov.br/api/v3/malhas/estados/' + municipio.microrregiao.mesorregiao.UF.id + '" />' +
        '<br><b>Região: </b>' + municipio.microrregiao.mesorregiao.UF.regiao.nome + ' - ' + municipio.microrregiao.mesorregiao.UF.regiao.sigla + ' (' + municipio.microrregiao.mesorregiao.UF.regiao.id + ') ' +
        '<img height="12px" src="https://servicodados.ibge.gov.br/api/v3/malhas/regioes/' + municipio.microrregiao.mesorregiao.UF.regiao.id + '" />' +
        '<hr><b>Área aprox.: </b>' + areaInKm2Of(poligono) +
        '<br><b>Perímetro aprox.: </b>' + perimetroInKm(poligono) +
        '<br><b>Ponto central: </b>' + (center ? '<br><b style="margin-left:10px">Latitude: </b>' + coord2Text(center[1]) + ' <b>Longitude: </b>' + coord2Text(center[0]) : 'Não foi possível localizar')
}
export const estiloContorno = { color: 'red', weight: 2, fill: false }
export const estiloArea = {}

export const loadMunicipios = async (mapa: L.Map | null, municipios: Array<any>, progress?: any) => {
    if (mapa) {
        clearMap(mapa)
        await municipios.map(async (municipio: any, index: number, array: any[]) => {
            await loadMunicipio(mapa, municipio, estiloArea, false)
            progress && progress(index)
        })
    }
}

type Color = string;

export function generateColorPalette(nColors: number): Color[] {
    const basicColors: Color[] = [
        // '#F94144', '#F3722C', '#F8961E', '#F9844A', '#F9C74F', '#90BE6D', '#43AA8B', '#4D908E', '#577590', '#277DA1'
        // '#ff0000', '#ff8700', '#ffd300', '#deff0a', '#a1ff0a', '#0aff99', '#0aefff', '#147df5', '#580aff', '#be0aff'
        // '#ffadad', '#ffd6a5', '#fdffb6', '#caffbf', '#9bf6ff', '#a0c4ff', '#bdb2ff', '#ffc6ff', '#fffffc'
        // '#ff2020', '#ff9819', '#f8ff27', '#4eff2a', '#12ebff', '#166fff', '#4423ff', '#ff31ff', '#ffff53'
        '#ff2020', '#ff9819', '#f8ff27', '#4eff2a', '#12ebff', '#4423ff', '#ff31ff', '#ffff53'
        // '#6a00ff', '#ff00ff', '#ff0040', '#ff9500', '#ffff00', '#aaff00', '#00ff15', '#00ffff', '#0095ff'
        // '#33a8c7', '#52e3e1', '#a0e426', '#fdf148', '#ffab00', '#f77976', '#f050ae', '#d883ff', '#9336fd'
        // "#FF0000", "#00FF00", "#0000FF", // Red, Green, Blue
        // "#FFFF00", "#FF00FF", "#00FFFF", // Yellow, Magenta, Cyan
        // "#000000", "#FFFFFF"            // Black, White
    ];

    const colorPalette: Color[] = [];
    let i = 0
    for (let j = 0; j < nColors; j++) {
        i = j === basicColors.length ? 0 : j
        colorPalette.push(basicColors[i++])
    }

    return colorPalette;
}

export const getColors4Meso = (municipios: Array<any>) => {
    let max = municipios[0].microrregiao.mesorregiao.id
    let min = max
    municipios.forEach((m: any) => {
        if (max < m.microrregiao.mesorregiao.id) max = m.microrregiao.mesorregiao.id
        if (min > m.microrregiao.mesorregiao.id) min = m.microrregiao.mesorregiao.id
    })

    const colorsMeso: Array<any> = []
    generateColorPalette(max - min + 1).map((color: Color, index: number) => colorsMeso.push({ color: color, meso: min + index }));
    return colorsMeso

}
export const loadMunicipioss = (mapa: L.Map | null, municipios: Array<any>, progress?: any) => {
    if (mapa) {
        clearMap(mapa)

        const colors = getColors4Meso(municipios)
        municipios.map((municipio: any, index: number, array: any[]) => {
            // console.log('Id: ', municipio.municipio.id, " Municipio: ", municipio, " Mapa:", municipio.mapa);
            loadMapaMunicipio(mapa, municipio, { color: colors.find((c: any) => c.meso === municipio.microrregiao.mesorregiao.id).color }, false)
            progress && progress(index)
        })
    }
}

export const loadMapaMunicipio = (mapa: L.Map | null, municipio: any, estilo = estiloArea, flyTo = true) => {
    if (mapa)
        try {
            const poligono: L.GeoJSON | any = L.geoJSON(municipio.mapa)
            poligono.bindPopup(popUpForMunicipio(municipio, poligono))
            poligono.setStyle(estilo)
            mapa?.addLayer(poligono)
            if (flyTo)
                mapa.flyToBounds(poligono.getBounds())
            return poligono
        } catch (e: any) {
            console.log('Erro no carregamento do municipio ' + municipio.nome + ":", municipio)
            console.log('Descrição do erro', e)
        }
    return null
}

export const loadMunicipio = async (mapa: L.Map | null, municipio: any, estilo = estiloArea, flyTo = true) => {
    if (mapa)
        try {
            const response = await fetch('https://servicodados.ibge.gov.br/api/v3/malhas/municipios/' + municipio.id + '?formato=application/vnd.geo+json')
            const data = await response.json()
            console.log('Mapa Data Json: ', data)
            const poligono: L.GeoJSON | any = L.geoJSON(data)
            poligono.bindPopup(popUpForMunicipio(municipio, poligono))
            poligono.setStyle(estilo)
            mapa?.addLayer(poligono)

            if (flyTo)
                mapa.flyToBounds(poligono.getBounds())
            return poligono
        } catch (e: any) {
            console.log('Erro no carregamento do municipio ' + municipio.nome, municipio)
            console.log('Descrição do erro', e)
        }
    return null
}

export const loadPropriedades = async (mapa: L.Map | null, propriedades: Array<any>) => {
    if (mapa) {
        propriedades.forEach(async (propriedade: any) => {
            const poligonos = await carregarZipFromURL(propriedade.poligono, {
                color: 'cyan',
                fillColor: 'cyan',
                fillOpacity: 0.3
            })
            poligonos.eachLayer(poligono => {
                poligono.bindPopup(popUpForPropriedade(propriedade, poligono))
                mapa.addLayer(poligono)
            })
        })
    }
}



const gpsIconPNG = new L.Icon({
    iconUrl: Marker_PNG,
    iconSize: [40, 40], // size of the icon
    iconAnchor: [20, 40], // point of the icon which will correspond to marker's location

    popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
});

const gpsIconSVG = L.divIcon({
    html: renderToString(<Marker />),
    iconSize: [800, 800],
    iconAnchor: [400, 800],
    popupAnchor: [0, 0],
});

export const goHome = (mapa: L.Map | null) => {
    if ("geolocation" in navigator) {
        navigator.geolocation?.getCurrentPosition(function (position: any) {
            mapa?.flyTo([position.coords.latitude, position.coords.longitude], 20, { duration: 2, easeLinearity: 0.25 })
            mapa?.addLayer(L.marker([position.coords.latitude, position.coords.longitude], { icon: gpsIconPNG }).bindPopup('Sua localização é:<br>Latitude: ' + position.coords.latitude + '<br>Longitude: ' + position.coords.longitude).openPopup());
        });
    } else {
        console.log("Geolocalização não é suportada neste navegador.");
    }
}

export function openFile(e: ChangeEvent<HTMLInputElement>, mapa: L.Map | null) {
    console.log('e', e)
    if (e.target.files) {
        const file = e.target.files[0]
        console.log('File', file)
        if (file) {
            if (file.name.split('.').pop() === 'kml') {
                console.log('Open KML')
                var reader = new FileReader();
                reader.readAsText(file);
                reader.onload = (e) => {
                    const parser = new DOMParser();
                    console.log('Reader Result:', reader.result)
                    if (reader.result) {
                        const content = parser.parseFromString(reader.result as string, "text/xml");

                        const geojson = togeojson.kml(content);
                        const poligono = L.geoJSON(geojson)
                        // poligono.addTo(map);
                        mapa?.addLayer(poligono)
                        // if (editableLayersRef.current)
                        //     editableLayersRef.current.addLayer(poligono)
                        mapa?.flyToBounds(poligono.getBounds())
                    }
                }
            }
            else if (file.name.split('.').pop() === 'zip') {
                console.log('Open ZIP')
                const reader = new FileReader();

                reader.onload = async (event: any) => {
                    const zipArrayBuffer = event.target.result;
                    const zip = new JSZip();
                    const zipFile = await zip.loadAsync(zipArrayBuffer);

                    const shpFile = zipFile.file(/\.shp$/)[0];
                    console.log('SHP FILE', shpFile)
                    const shxFile = zipFile.file(/\.shx$/)[0];

                    if (shpFile && shxFile) {
                        const shpArrayBuffer = await shpFile.async('arraybuffer');
                        // const shxArrayBuffer = await shxFile.async('arraybuffer');
                        console.log('SHP BUFFER', shpArrayBuffer)

                        // const geojson = shpjs.combine([shpArrayBuffer, shxArrayBuffer]);
                        // Realize qualquer processamento adicional necessário com o geojson carregado
                        // console.log('GeoJSON carregado:', geojson);


                        shapefile.openShp(shpArrayBuffer).then((a) => {
                            console.log('open shp')
                            a.read().then(function log(result: any): any {
                                console.log('result', { geometry: result.value, properties: { _enableLayerEdit: true } })
                                if (result.done) return;
                                // if (editableLayersRef.current)
                                // editableLayersRef.current.addLayer(L.geoJSON(result.value))
                                var shape: any = L.geoJSON(result.value)
                                console.log('shape', shape)
                                shape.options = { ...shape.options, enable: true }
                                // console.log('Map', map)
                                // shape.addTo(map)
                                // shape.addTo(L.map(mapRef.current))
                                mapa?.addLayer(shape)
                                // if (editableLayersRef.current)
                                //     editableLayersRef.current.addLayer(shape)
                                // editableLayersRef.current.addLayer(
                                //   L.geoJSON(result.value,
                                //     {
                                //       style: {
                                //         color: 'gray',
                                //         // fillColor: 'gray',
                                //         fillOpacity: 0.2,
                                //       },
                                //     }));
                                mapa?.flyToBounds(shape.getBounds())
                                return a.read().then(log);
                            })
                        });

                    } else {
                        console.error('Arquivos .shp e .shx não encontrados no arquivo ZIP.');
                    }
                };

                reader.readAsArrayBuffer(file);
            }
        }
    }

}

export const salvarShapefile = async (mapa: L.Map | null) => {
    // Verifique se há formas editáveis para exportar
    // if (editableLayersRef.current && editableLayersRef.current.getLayers().length > 0) {

    // editableLayersRef.current.getLayers().forEach((layer: any) => {
    // Se a forma desenhada for um círculo, converta-a em um polígono
    //     if (layer instanceof L.Circle) {
    //         const polygon = L.PM.Utils.circleToPolygon(layer, 60)
    //         if (editableLayersRef.current) {
    //             editableLayersRef.current.addLayer(polygon);
    //             editableLayersRef.current.removeLayer(layer);
    //         }
    //     }
    // })

    var fg = L.featureGroup();
    if (mapa) {
        circleToPolygon(mapa)
        mapa.eachLayer((layer) => {
            if (layer instanceof L.Polygon) {
                fg.addLayer(layer);
            }
        });
        // console.log(fg.toGeoJSON());

        // Converta as formas editáveis para o formato que a biblioteca shp-write espe  ra
        // const geojson: any = editableLayersRef.current.toGeoJSON();
        const geojson: any = fg.toGeoJSON()
        const options: any = {
            // folder: "dados",
            filename: "fazenda",
            outputType: "blob",
            compression: "STORE",
            types: {
                // point: "mypoints",
                polygon: "polygons",
                // polyline: "mylines",
            },
            // prj: 'PROJCS["Amersfoort / RD New",GEOGCS["Amersfoort",DATUM["D_Amersfoort",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0],UNIT["Degree",0.017453292519943295]],PROJECTION["Stereographic_North_Pole"],PARAMETER["standard_parallel_1",52.15616055555555],PARAMETER["central_meridian",5.38763888888889],PARAMETER["scale_factor",0.9999079],PARAMETER["false_easting",155000],PARAMETER["false_northing",463000],UNIT["Meter",1]]'
        };

        // Use a biblioteca shp-write para criar o arquivo shapefile
        // const shapefileBuffer: any = shp.zip(geojson, options);

        shp.download(geojson, options)

    } else {
        alert('Não há formas editáveis para exportar.');
    }
};


export const getShapefileBuffer = async (mapa: L.Map | null) => {

    var fg = L.featureGroup();
    if (mapa) {
        circleToPolygon(mapa)
        mapa.eachLayer((layer) => {
            // console.log('Count', mapa.get)
            console.log('Layer', layer)
            if (layer instanceof L.Polygon) {
                fg.addLayer(layer);
            }
        });
        // console.log(fg.toGeoJSON());

        // Converta as formas editáveis para o formato que a biblioteca shp-write espe  ra
        // const geojson: any = editableLayersRef.current.toGeoJSON();
        const geojson: any = fg.toGeoJSON()

        const options: any = {
            // folder: "dados",
            filename: "fazenda",
            outputType: "blob",
            compression: "STORE",
            types: {
                // point: "mypoints",
                polygon: "polygons",
                // polyline: "mylines",
            },
            // prj: 'PROJCS["Amersfoort / RD New",GEOGCS["Amersfoort",DATUM["D_Amersfoort",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0],UNIT["Degree",0.017453292519943295]],PROJECTION["Stereographic_North_Pole"],PARAMETER["standard_parallel_1",52.15616055555555],PARAMETER["central_meridian",5.38763888888889],PARAMETER["scale_factor",0.9999079],PARAMETER["false_easting",155000],PARAMETER["false_northing",463000],UNIT["Meter",1]]'
        };

        // Use a biblioteca shp-write para criar o arquivo Shapefile
        const shapefileBuffer: any = await shp.zip(geojson, options);
        console.log('Buffer', shapefileBuffer)
        return shapefileBuffer;
    }

}

// L.TileLayer.Provider.providers = {
//     OpenStreetMap: {
//         url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
//         options: {
//             maxZoom: 19,
//             attribution:
//                 '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         },
//         variants: {
//             Mapnik: {},
//             DE: {
//                 url: 'https://tile.openstreetmap.de/{z}/{x}/{y}.png',
//                 options: {
//                     maxZoom: 18
//                 }
//             },
//             CH: {
//                 url: 'https://tile.osm.ch/switzerland/{z}/{x}/{y}.png',
//                 options: {
//                     maxZoom: 18,
//                     bounds: [[45, 5], [48, 11]]
//                 }
//             },
//             France: {
//                 url: 'https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png',
//                 options: {
//                     maxZoom: 20,
//                     attribution: '&copy; OpenStreetMap France | {attribution.OpenStreetMap}'
//                 }
//             },
//             HOT: {
//                 url: 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
//                 options: {
//                     attribution:
//                         '{attribution.OpenStreetMap}, ' +
//                         'Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> ' +
//                         'hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>'
//                 }
//             },
//             BZH: {
//                 url: 'https://tile.openstreetmap.bzh/br/{z}/{x}/{y}.png',
//                 options: {
//                     attribution: '{attribution.OpenStreetMap}, Tiles courtesy of <a href="http://www.openstreetmap.bzh/" target="_blank">Breton OpenStreetMap Team</a>',
//                     bounds: [[46.2, -5.5], [50, 0.7]]
//                 }
//             }
//         }
//     },
//     MapTilesAPI: {
//         url: 'https://maptiles.p.rapidapi.com/{variant}/{z}/{x}/{y}.png?rapidapi-key={apikey}',
//         options: {
//             attribution:
//                 '&copy; <a href="http://www.maptilesapi.com/">MapTiles API</a>, {attribution.OpenStreetMap}',
//             variant: 'en/map/v1',
//             // Get your own MapTiles API access token here : https://www.maptilesapi.com/
//             // NB : this is a demonstration key that comes with no guarantee and not to be used in production
//             apikey: '<insert your api key here>',
//             maxZoom: 19
//         },
//         variants: {
//             OSMEnglish: {
//                 options: {
//                     variant: 'en/map/v1'
//                 }
//             },
//             OSMFrancais: {
//                 options: {
//                     variant: 'fr/map/v1'
//                 }
//             },
//             OSMEspagnol: {
//                 options: {
//                     variant: 'es/map/v1'
//                 }
//             }
//         }
//     },
//     OpenSeaMap: {
//         url: 'https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png',
//         options: {
//             attribution: 'Map data: &copy; <a href="http://www.openseamap.org">OpenSeaMap</a> contributors'
//         }
//     },
//     OPNVKarte: {
//         url: 'https://tileserver.memomaps.de/tilegen/{z}/{x}/{y}.png',
//         options: {
//             maxZoom: 18,
//             attribution: 'Map <a href="https://memomaps.de/">memomaps.de</a> <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, map data {attribution.OpenStreetMap}'
//         }
//     },
//     OpenTopoMap: {
//         url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
//         options: {
//             maxZoom: 17,
//             attribution: 'Map data: {attribution.OpenStreetMap}, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
//         }
//     },
//     OpenRailwayMap: {
//         url: 'https://{s}.tiles.openrailwaymap.org/standard/{z}/{x}/{y}.png',
//         options: {
//             maxZoom: 19,
//             attribution: 'Map data: {attribution.OpenStreetMap} | Map style: &copy; <a href="https://www.OpenRailwayMap.org">OpenRailwayMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
//         }
//     },
//     OpenFireMap: {
//         url: 'http://openfiremap.org/hytiles/{z}/{x}/{y}.png',
//         options: {
//             maxZoom: 19,
//             attribution: 'Map data: {attribution.OpenStreetMap} | Map style: &copy; <a href="http://www.openfiremap.org">OpenFireMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
//         }
//     },
//     SafeCast: {
//         url: 'https://s3.amazonaws.com/te512.safecast.org/{z}/{x}/{y}.png',
//         options: {
//             maxZoom: 16,
//             attribution: 'Map data: {attribution.OpenStreetMap} | Map style: &copy; <a href="https://blog.safecast.org/about/">SafeCast</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
//         }
//     },
//     Stadia: {
//         url: 'https://tiles.stadiamaps.com/tiles/{variant}/{z}/{x}/{y}{r}.{ext}',
//         options: {
//             minZoom: 0,
//             maxZoom: 20,
//             attribution:
//                 '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> ' +
//                 '&copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> ' +
//                 '{attribution.OpenStreetMap}',
//             variant: 'alidade_smooth',
//             ext: 'png'
//         },
//         variants: {
//             AlidadeSmooth: 'alidade_smooth',
//             AlidadeSmoothDark: 'alidade_smooth_dark',
//             OSMBright: 'osm_bright',
//             Outdoors: 'outdoors',
//             StamenToner: {
//                 options: {
//                     attribution:
//                         '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> ' +
//                         '&copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> ' +
//                         '&copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> ' +
//                         '{attribution.OpenStreetMap}',
//                     variant: 'stamen_toner'
//                 }
//             },
//             StamenTonerBackground: {
//                 options: {
//                     attribution:
//                         '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> ' +
//                         '&copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> ' +
//                         '&copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> ' +
//                         '{attribution.OpenStreetMap}',
//                     variant: 'stamen_toner_background'
//                 }
//             },
//             StamenTonerLines: {
//                 options: {
//                     attribution:
//                         '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> ' +
//                         '&copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> ' +
//                         '&copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> ' +
//                         '{attribution.OpenStreetMap}',
//                     variant: 'stamen_toner_lines'
//                 }
//             },
//             StamenTonerLabels: {
//                 options: {
//                     attribution:
//                         '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> ' +
//                         '&copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> ' +
//                         '&copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> ' +
//                         '{attribution.OpenStreetMap}',
//                     variant: 'stamen_toner_labels'
//                 }
//             },
//             StamenTonerLite: {
//                 options: {
//                     attribution:
//                         '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> ' +
//                         '&copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> ' +
//                         '&copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> ' +
//                         '{attribution.OpenStreetMap}',
//                     variant: 'stamen_toner_lite'
//                 }
//             },
//             StamenWatercolor: {
//                 url: 'https://tiles.stadiamaps.com/tiles/{variant}/{z}/{x}/{y}.{ext}',
//                 options: {
//                     attribution:
//                         '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> ' +
//                         '&copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> ' +
//                         '&copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> ' +
//                         '{attribution.OpenStreetMap}',
//                     variant: 'stamen_watercolor',
//                     ext: 'jpg',
//                     minZoom: 1,
//                     maxZoom: 16
//                 }
//             },
//             StamenTerrain: {
//                 options: {
//                     attribution:
//                         '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> ' +
//                         '&copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> ' +
//                         '&copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> ' +
//                         '{attribution.OpenStreetMap}',
//                     variant: 'stamen_terrain',
//                     minZoom: 0,
//                     maxZoom: 18
//                 }
//             },
//             StamenTerrainBackground: {
//                 options: {
//                     attribution:
//                         '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> ' +
//                         '&copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> ' +
//                         '&copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> ' +
//                         '{attribution.OpenStreetMap}',
//                     variant: 'stamen_terrain_background',
//                     minZoom: 0,
//                     maxZoom: 18
//                 }
//             },
//             StamenTerrainLabels: {
//                 options: {
//                     attribution:
//                         '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> ' +
//                         '&copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> ' +
//                         '&copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> ' +
//                         '{attribution.OpenStreetMap}',
//                     variant: 'stamen_terrain_labels',
//                     minZoom: 0,
//                     maxZoom: 18
//                 }
//             },
//             StamenTerrainLines: {
//                 options: {
//                     attribution:
//                         '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> ' +
//                         '&copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> ' +
//                         '&copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> ' +
//                         '{attribution.OpenStreetMap}',
//                     variant: 'stamen_terrain_lines',
//                     minZoom: 0,
//                     maxZoom: 18
//                 }
//             }
//         }
//     },
//     Thunderforest: {
//         url: 'https://{s}.tile.thunderforest.com/{variant}/{z}/{x}/{y}.png?apikey={apikey}',
//         options: {
//             attribution:
//                 '&copy; <a href="http://www.thunderforest.com/">Thunderforest</a>, {attribution.OpenStreetMap}',
//             variant: 'cycle',
//             apikey: '<insert your api key here>',
//             maxZoom: 22
//         },
//         variants: {
//             OpenCycleMap: 'cycle',
//             Transport: {
//                 options: {
//                     variant: 'transport'
//                 }
//             },
//             TransportDark: {
//                 options: {
//                     variant: 'transport-dark'
//                 }
//             },
//             SpinalMap: {
//                 options: {
//                     variant: 'spinal-map'
//                 }
//             },
//             Landscape: 'landscape',
//             Outdoors: 'outdoors',
//             Pioneer: 'pioneer',
//             MobileAtlas: 'mobile-atlas',
//             Neighbourhood: 'neighbourhood'
//         }
//     },
//     CyclOSM: {
//         url: 'https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png',
//         options: {
//             maxZoom: 20,
//             attribution: '<a href="https://github.com/cyclosm/cyclosm-cartocss-style/releases" title="CyclOSM - Open Bicycle render">CyclOSM</a> | Map data: {attribution.OpenStreetMap}'
//         }
//     },
//     Jawg: {
//         url: 'https://{s}.tile.jawg.io/{variant}/{z}/{x}/{y}{r}.png?access-token={accessToken}',
//         options: {
//             attribution:
//                 '<a href="http://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> ' +
//                 '{attribution.OpenStreetMap}',
//             minZoom: 0,
//             maxZoom: 22,
//             subdomains: 'abcd',
//             variant: 'jawg-terrain',
//             // Get your own Jawg access token here : https://www.jawg.io/lab/
//             // NB : this is a demonstration key that comes with no guarantee
//             accessToken: '<insert your access token here>',
//         },
//         variants: {
//             Streets: 'jawg-streets',
//             Terrain: 'jawg-terrain',
//             Sunny: 'jawg-sunny',
//             Dark: 'jawg-dark',
//             Light: 'jawg-light',
//             Matrix: 'jawg-matrix'
//         }
//     },
//     MapBox: {
//         url: 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}{r}?access_token={accessToken}',
//         options: {
//             attribution:
//                 '&copy; <a href="https://www.mapbox.com/about/maps/" target="_blank">Mapbox</a> ' +
//                 '{attribution.OpenStreetMap} ' +
//                 '<a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a>',
//             tileSize: 512,
//             maxZoom: 18,
//             zoomOffset: -1,
//             id: 'mapbox/streets-v11',
//             accessToken: '<insert your access token here>',
//         }
//     },
//     MapTiler: {
//         url: 'https://api.maptiler.com/maps/{variant}/{z}/{x}/{y}{r}.{ext}?key={key}',
//         options: {
//             attribution:
//                 '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
//             variant: 'streets',
//             ext: 'png',
//             key: '<insert your MapTiler Cloud API key here>',
//             tileSize: 512,
//             zoomOffset: -1,
//             minZoom: 0,
//             maxZoom: 21
//         },
//         variants: {
//             Streets: 'streets',
//             Basic: 'basic',
//             Bright: 'bright',
//             Pastel: 'pastel',
//             Positron: 'positron',
//             Hybrid: {
//                 options: {
//                     variant: 'hybrid',
//                     ext: 'jpg'
//                 }
//             },
//             Toner: 'toner',
//             Topo: 'topo',
//             Voyager: 'voyager',
//             Ocean: 'ocean',
//             Backdrop: 'backdrop',
//             Dataviz: 'dataviz'
//         }
//     },
//     TomTom: {
//         url: 'https://{s}.api.tomtom.com/map/1/tile/{variant}/{style}/{z}/{x}/{y}.{ext}?key={apikey}',
//         options: {
//             variant: 'basic',
//             maxZoom: 22,
//             attribution:
//                 '<a href="https://tomtom.com" target="_blank">&copy;  1992 - ' + new Date().getFullYear() + ' TomTom.</a> ',
//             subdomains: 'abcd',
//             style: 'main',
//             ext: 'png',
//             apikey: '<insert your API key here>',
//         },
//         variants: {
//             Basic: 'basic',
//             Hybrid: 'hybrid',
//             Labels: 'labels'
//         }
//     },
//     Esri: {
//         url: 'https://server.arcgisonline.com/ArcGIS/rest/services/{variant}/MapServer/tile/{z}/{y}/{x}',
//         options: {
//             variant: 'World_Street_Map',
//             attribution: 'Tiles &copy; Esri'
//         },
//         variants: {
//             WorldStreetMap: {
//                 options: {
//                     attribution:
//                         '{attribution.Esri} &mdash; ' +
//                         'Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012'
//                 }
//             },
//             DeLorme: {
//                 options: {
//                     variant: 'Specialty/DeLorme_World_Base_Map',
//                     minZoom: 1,
//                     maxZoom: 11,
//                     attribution: '{attribution.Esri} &mdash; Copyright: &copy;2012 DeLorme'
//                 }
//             },
//             WorldTopoMap: {
//                 options: {
//                     variant: 'World_Topo_Map',
//                     attribution:
//                         '{attribution.Esri} &mdash; ' +
//                         'Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community'
//                 }
//             },
//             WorldImagery: {
//                 options: {
//                     variant: 'World_Imagery',
//                     attribution:
//                         '{attribution.Esri} &mdash; ' +
//                         'Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
//                 }
//             },
//             WorldTerrain: {
//                 options: {
//                     variant: 'World_Terrain_Base',
//                     maxZoom: 13,
//                     attribution:
//                         '{attribution.Esri} &mdash; ' +
//                         'Source: USGS, Esri, TANA, DeLorme, and NPS'
//                 }
//             },
//             WorldShadedRelief: {
//                 options: {
//                     variant: 'World_Shaded_Relief',
//                     maxZoom: 13,
//                     attribution: '{attribution.Esri} &mdash; Source: Esri'
//                 }
//             },
//             WorldPhysical: {
//                 options: {
//                     variant: 'World_Physical_Map',
//                     maxZoom: 8,
//                     attribution: '{attribution.Esri} &mdash; Source: US National Park Service'
//                 }
//             },
//             OceanBasemap: {
//                 options: {
//                     variant: 'Ocean/World_Ocean_Base',
//                     maxZoom: 13,
//                     attribution: '{attribution.Esri} &mdash; Sources: GEBCO, NOAA, CHS, OSU, UNH, CSUMB, National Geographic, DeLorme, NAVTEQ, and Esri'
//                 }
//             },
//             NatGeoWorldMap: {
//                 options: {
//                     variant: 'NatGeo_World_Map',
//                     maxZoom: 16,
//                     attribution: '{attribution.Esri} &mdash; National Geographic, Esri, DeLorme, NAVTEQ, UNEP-WCMC, USGS, NASA, ESA, METI, NRCAN, GEBCO, NOAA, iPC'
//                 }
//             },
//             WorldGrayCanvas: {
//                 options: {
//                     variant: 'Canvas/World_Light_Gray_Base',
//                     maxZoom: 16,
//                     attribution: '{attribution.Esri} &mdash; Esri, DeLorme, NAVTEQ'
//                 }
//             }
//         }
//     },
//     OpenWeatherMap: {
//         url: 'http://{s}.tile.openweathermap.org/map/{variant}/{z}/{x}/{y}.png?appid={apiKey}',
//         options: {
//             maxZoom: 19,
//             attribution: 'Map data &copy; <a href="http://openweathermap.org">OpenWeatherMap</a>',
//             apiKey: '<insert your api key here>',
//             opacity: 0.5
//         },
//         variants: {
//             Clouds: 'clouds',
//             CloudsClassic: 'clouds_cls',
//             Precipitation: 'precipitation',
//             PrecipitationClassic: 'precipitation_cls',
//             Rain: 'rain',
//             RainClassic: 'rain_cls',
//             Pressure: 'pressure',
//             PressureContour: 'pressure_cntr',
//             Wind: 'wind',
//             Temperature: 'temp',
//             Snow: 'snow'
//         }
//     },
//     HERE: {
//         /*
//          * HERE maps, formerly Nokia maps.
//          * These basemaps are free, but you need an api id and app key. Please sign up at
//          * https://developer.here.com/plans
//          */
//         url:
//             'https://{s}.{base}.maps.api.here.com/maptile/2.1/' +
//             '{type}/{mapID}/{variant}/{z}/{x}/{y}/{size}/{format}?' +
//             'app_id={app_id}&app_code={app_code}&lg={language}',
//         options: {
//             attribution:
//                 'Map &copy; 1987-' + new Date().getFullYear() + ' <a href="http://developer.here.com">HERE</a>',
//             subdomains: '1234',
//             mapID: 'newest',
//             'app_id': '<insert your app_id here>',
//             'app_code': '<insert your app_code here>',
//             base: 'base',
//             variant: 'normal.day',
//             maxZoom: 20,
//             type: 'maptile',
//             language: 'eng',
//             format: 'png8',
//             size: '256'
//         },
//         variants: {
//             normalDay: 'normal.day',
//             normalDayCustom: 'normal.day.custom',
//             normalDayGrey: 'normal.day.grey',
//             normalDayMobile: 'normal.day.mobile',
//             normalDayGreyMobile: 'normal.day.grey.mobile',
//             normalDayTransit: 'normal.day.transit',
//             normalDayTransitMobile: 'normal.day.transit.mobile',
//             normalDayTraffic: {
//                 options: {
//                     variant: 'normal.traffic.day',
//                     base: 'traffic',
//                     type: 'traffictile'
//                 }
//             },
//             normalNight: 'normal.night',
//             normalNightMobile: 'normal.night.mobile',
//             normalNightGrey: 'normal.night.grey',
//             normalNightGreyMobile: 'normal.night.grey.mobile',
//             normalNightTransit: 'normal.night.transit',
//             normalNightTransitMobile: 'normal.night.transit.mobile',
//             reducedDay: 'reduced.day',
//             reducedNight: 'reduced.night',
//             basicMap: {
//                 options: {
//                     type: 'basetile'
//                 }
//             },
//             mapLabels: {
//                 options: {
//                     type: 'labeltile',
//                     format: 'png'
//                 }
//             },
//             trafficFlow: {
//                 options: {
//                     base: 'traffic',
//                     type: 'flowtile'
//                 }
//             },
//             carnavDayGrey: 'carnav.day.grey',
//             hybridDay: {
//                 options: {
//                     base: 'aerial',
//                     variant: 'hybrid.day'
//                 }
//             },
//             hybridDayMobile: {
//                 options: {
//                     base: 'aerial',
//                     variant: 'hybrid.day.mobile'
//                 }
//             },
//             hybridDayTransit: {
//                 options: {
//                     base: 'aerial',
//                     variant: 'hybrid.day.transit'
//                 }
//             },
//             hybridDayGrey: {
//                 options: {
//                     base: 'aerial',
//                     variant: 'hybrid.grey.day'
//                 }
//             },
//             hybridDayTraffic: {
//                 options: {
//                     variant: 'hybrid.traffic.day',
//                     base: 'traffic',
//                     type: 'traffictile'
//                 }
//             },
//             pedestrianDay: 'pedestrian.day',
//             pedestrianNight: 'pedestrian.night',
//             satelliteDay: {
//                 options: {
//                     base: 'aerial',
//                     variant: 'satellite.day'
//                 }
//             },
//             terrainDay: {
//                 options: {
//                     base: 'aerial',
//                     variant: 'terrain.day'
//                 }
//             },
//             terrainDayMobile: {
//                 options: {
//                     base: 'aerial',
//                     variant: 'terrain.day.mobile'
//                 }
//             }
//         }
//     },
//     HEREv3: {
//         /*
//          * HERE maps API Version 3.
//          * These basemaps are free, but you need an API key. Please sign up at
//          * https://developer.here.com/plans
//          * Version 3 deprecates the app_id and app_code access in favor of apiKey
//          *
//          * Supported access methods as of 2019/12/21:
//          * @see https://developer.here.com/faqs#access-control-1--how-do-you-control-access-to-here-location-services
//          */
//         url:
//             'https://{s}.{base}.maps.ls.hereapi.com/maptile/2.1/' +
//             '{type}/{mapID}/{variant}/{z}/{x}/{y}/{size}/{format}?' +
//             'apiKey={apiKey}&lg={language}',
//         options: {
//             attribution:
//                 'Map &copy; 1987-' + new Date().getFullYear() + ' <a href="http://developer.here.com">HERE</a>',
//             subdomains: '1234',
//             mapID: 'newest',
//             apiKey: '<insert your apiKey here>',
//             base: 'base',
//             variant: 'normal.day',
//             maxZoom: 20,
//             type: 'maptile',
//             language: 'eng',
//             format: 'png8',
//             size: '256'
//         },
//         variants: {
//             normalDay: 'normal.day',
//             normalDayCustom: 'normal.day.custom',
//             normalDayGrey: 'normal.day.grey',
//             normalDayMobile: 'normal.day.mobile',
//             normalDayGreyMobile: 'normal.day.grey.mobile',
//             normalDayTransit: 'normal.day.transit',
//             normalDayTransitMobile: 'normal.day.transit.mobile',
//             normalNight: 'normal.night',
//             normalNightMobile: 'normal.night.mobile',
//             normalNightGrey: 'normal.night.grey',
//             normalNightGreyMobile: 'normal.night.grey.mobile',
//             normalNightTransit: 'normal.night.transit',
//             normalNightTransitMobile: 'normal.night.transit.mobile',
//             reducedDay: 'reduced.day',
//             reducedNight: 'reduced.night',
//             basicMap: {
//                 options: {
//                     type: 'basetile'
//                 }
//             },
//             mapLabels: {
//                 options: {
//                     type: 'labeltile',
//                     format: 'png'
//                 }
//             },
//             trafficFlow: {
//                 options: {
//                     base: 'traffic',
//                     type: 'flowtile'
//                 }
//             },
//             carnavDayGrey: 'carnav.day.grey',
//             hybridDay: {
//                 options: {
//                     base: 'aerial',
//                     variant: 'hybrid.day'
//                 }
//             },
//             hybridDayMobile: {
//                 options: {
//                     base: 'aerial',
//                     variant: 'hybrid.day.mobile'
//                 }
//             },
//             hybridDayTransit: {
//                 options: {
//                     base: 'aerial',
//                     variant: 'hybrid.day.transit'
//                 }
//             },
//             hybridDayGrey: {
//                 options: {
//                     base: 'aerial',
//                     variant: 'hybrid.grey.day'
//                 }
//             },
//             pedestrianDay: 'pedestrian.day',
//             pedestrianNight: 'pedestrian.night',
//             satelliteDay: {
//                 options: {
//                     base: 'aerial',
//                     variant: 'satellite.day'
//                 }
//             },
//             terrainDay: {
//                 options: {
//                     base: 'aerial',
//                     variant: 'terrain.day'
//                 }
//             },
//             terrainDayMobile: {
//                 options: {
//                     base: 'aerial',
//                     variant: 'terrain.day.mobile'
//                 }
//             }
//         }
//     },
//     FreeMapSK: {
//         url: 'https://{s}.freemap.sk/T/{z}/{x}/{y}.jpeg',
//         options: {
//             minZoom: 8,
//             maxZoom: 16,
//             subdomains: 'abcd',
//             bounds: [[47.204642, 15.996093], [49.830896, 22.576904]],
//             attribution:
//                 '{attribution.OpenStreetMap}, visualization CC-By-SA 2.0 <a href="http://freemap.sk">Freemap.sk</a>'
//         }
//     },
//     MtbMap: {
//         url: 'http://tile.mtbmap.cz/mtbmap_tiles/{z}/{x}/{y}.png',
//         options: {
//             attribution:
//                 '{attribution.OpenStreetMap} &amp; USGS'
//         }
//     },
//     CartoDB: {
//         url: 'https://{s}.basemaps.cartocdn.com/{variant}/{z}/{x}/{y}{r}.png',
//         options: {
//             attribution: '{attribution.OpenStreetMap} &copy; <a href="https://carto.com/attributions">CARTO</a>',
//             subdomains: 'abcd',
//             maxZoom: 20,
//             variant: 'light_all'
//         },
//         variants: {
//             Positron: 'light_all',
//             PositronNoLabels: 'light_nolabels',
//             PositronOnlyLabels: 'light_only_labels',
//             DarkMatter: 'dark_all',
//             DarkMatterNoLabels: 'dark_nolabels',
//             DarkMatterOnlyLabels: 'dark_only_labels',
//             Voyager: 'rastertiles/voyager',
//             VoyagerNoLabels: 'rastertiles/voyager_nolabels',
//             VoyagerOnlyLabels: 'rastertiles/voyager_only_labels',
//             VoyagerLabelsUnder: 'rastertiles/voyager_labels_under'
//         }
//     },
//     HikeBike: {
//         url: 'https://tiles.wmflabs.org/{variant}/{z}/{x}/{y}.png',
//         options: {
//             maxZoom: 19,
//             attribution: '{attribution.OpenStreetMap}',
//             variant: 'hikebike'
//         },
//         variants: {
//             HikeBike: {},
//             HillShading: {
//                 options: {
//                     maxZoom: 15,
//                     variant: 'hillshading'
//                 }
//             }
//         }
//     },
//     BasemapAT: {
//         url: 'https://mapsneu.wien.gv.at/basemap/{variant}/{type}/google3857/{z}/{y}/{x}.{format}',
//         options: {
//             maxZoom: 19,
//             attribution: 'Datenquelle: <a href="https://www.basemap.at">basemap.at</a>',
//             type: 'normal',
//             format: 'png',
//             bounds: [[46.358770, 8.782379], [49.037872, 17.189532]],
//             variant: 'geolandbasemap'
//         },
//         variants: {
//             basemap: {
//                 options: {
//                     maxZoom: 20, // currently only in Vienna
//                     variant: 'geolandbasemap'
//                 }
//             },
//             grau: 'bmapgrau',
//             overlay: 'bmapoverlay',
//             terrain: {
//                 options: {
//                     variant: 'bmapgelaende',
//                     type: 'grau',
//                     format: 'jpeg'
//                 }
//             },
//             surface: {
//                 options: {
//                     variant: 'bmapoberflaeche',
//                     type: 'grau',
//                     format: 'jpeg'
//                 }
//             },
//             highdpi: {
//                 options: {
//                     variant: 'bmaphidpi',
//                     format: 'jpeg'
//                 }
//             },
//             orthofoto: {
//                 options: {
//                     maxZoom: 20, // currently only in Vienna
//                     variant: 'bmaporthofoto30cm',
//                     format: 'jpeg'
//                 }
//             }
//         }
//     },
//     nlmaps: {
//         url: 'https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0/{variant}/EPSG:3857/{z}/{x}/{y}.png',
//         options: {
//             minZoom: 6,
//             maxZoom: 19,
//             bounds: [[50.5, 3.25], [54, 7.6]],
//             attribution: 'Kaartgegevens &copy; <a href="https://www.kadaster.nl">Kadaster</a>'
//         },
//         variants: {
//             'standaard': 'standaard',
//             'pastel': 'pastel',
//             'grijs': 'grijs',
//             'water': 'water',
//             'luchtfoto': {
//                 'url': 'https://service.pdok.nl/hwh/luchtfotorgb/wmts/v1_0/Actueel_ortho25/EPSG:3857/{z}/{x}/{y}.jpeg',
//             }
//         }
//     },
//     NASAGIBS: {
//         url: 'https://map1.vis.earthdata.nasa.gov/wmts-webmerc/{variant}/default/{time}/{tilematrixset}{maxZoom}/{z}/{y}/{x}.{format}',
//         options: {
//             attribution:
//                 'Imagery provided by services from the Global Imagery Browse Services (GIBS), operated by the NASA/GSFC/Earth Science Data and Information System ' +
//                 '(<a href="https://earthdata.nasa.gov">ESDIS</a>) with funding provided by NASA/HQ.',
//             bounds: [[-85.0511287776, -179.999999975], [85.0511287776, 179.999999975]],
//             minZoom: 1,
//             maxZoom: 9,
//             format: 'jpg',
//             time: '',
//             tilematrixset: 'GoogleMapsCompatible_Level'
//         },
//         variants: {
//             ModisTerraTrueColorCR: 'MODIS_Terra_CorrectedReflectance_TrueColor',
//             ModisTerraBands367CR: 'MODIS_Terra_CorrectedReflectance_Bands367',
//             ViirsEarthAtNight2012: {
//                 options: {
//                     variant: 'VIIRS_CityLights_2012',
//                     maxZoom: 8
//                 }
//             },
//             ModisTerraLSTDay: {
//                 options: {
//                     variant: 'MODIS_Terra_Land_Surface_Temp_Day',
//                     format: 'png',
//                     maxZoom: 7,
//                     opacity: 0.75
//                 }
//             },
//             ModisTerraSnowCover: {
//                 options: {
//                     variant: 'MODIS_Terra_NDSI_Snow_Cover',
//                     format: 'png',
//                     maxZoom: 8,
//                     opacity: 0.75
//                 }
//             },
//             ModisTerraAOD: {
//                 options: {
//                     variant: 'MODIS_Terra_Aerosol',
//                     format: 'png',
//                     maxZoom: 6,
//                     opacity: 0.75
//                 }
//             },
//             ModisTerraChlorophyll: {
//                 options: {
//                     variant: 'MODIS_Terra_Chlorophyll_A',
//                     format: 'png',
//                     maxZoom: 7,
//                     opacity: 0.75
//                 }
//             }
//         }
//     },
//     NLS: {
//         // NLS maps are copyright National library of Scotland.
//         // http://maps.nls.uk/projects/api/index.html
//         // Please contact NLS for anything other than non-commercial low volume usage
//         //
//         // Map sources: Ordnance Survey 1:1m to 1:63K, 1920s-1940s
//         //   z0-9  - 1:1m
//         //  z10-11 - quarter inch (1:253440)
//         //  z12-18 - one inch (1:63360)
//         url: 'https://nls-{s}.tileserver.com/nls/{z}/{x}/{y}.jpg',
//         options: {
//             attribution: '<a href="http://geo.nls.uk/maps/">National Library of Scotland Historic Maps</a>',
//             bounds: [[49.6, -12], [61.7, 3]],
//             minZoom: 1,
//             maxZoom: 18,
//             subdomains: '0123',
//         }
//     },
//     JusticeMap: {
//         // Justice Map (http://www.justicemap.org/)
//         // Visualize race and income data for your community, county and country.
//         // Includes tools for data journalists, bloggers and community activists.
//         url: 'https://www.justicemap.org/tile/{size}/{variant}/{z}/{x}/{y}.png',
//         options: {
//             attribution: '<a href="http://www.justicemap.org/terms.php">Justice Map</a>',
//             // one of 'county', 'tract', 'block'
//             size: 'county',
//             // Bounds for USA, including Alaska and Hawaii
//             bounds: [[14, -180], [72, -56]]
//         },
//         variants: {
//             income: 'income',
//             americanIndian: 'indian',
//             asian: 'asian',
//             black: 'black',
//             hispanic: 'hispanic',
//             multi: 'multi',
//             nonWhite: 'nonwhite',
//             white: 'white',
//             plurality: 'plural'
//         }
//     },
//     GeoportailFrance: {
//         url: 'https://wxs.ign.fr/{apikey}/geoportail/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE={style}&TILEMATRIXSET=PM&FORMAT={format}&LAYER={variant}&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}',
//         options: {
//             attribution: '<a target="_blank" href="https://www.geoportail.gouv.fr/">Geoportail France</a>',
//             bounds: [[-75, -180], [81, 180]],
//             minZoom: 2,
//             maxZoom: 18,
//             // Get your own geoportail apikey here : http://professionnels.ign.fr/ign/contrats/
//             // NB : 'choisirgeoportail' is a demonstration key that comes with no guarantee
//             apikey: 'choisirgeoportail',
//             format: 'image/png',
//             style: 'normal',
//             variant: 'GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2'
//         },
//         variants: {
//             plan: 'GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2',
//             parcels: {
//                 options: {
//                     variant: 'CADASTRALPARCELS.PARCELLAIRE_EXPRESS',
//                     style: 'PCI vecteur',
//                     maxZoom: 20
//                 }
//             },
//             orthos: {
//                 options: {
//                     maxZoom: 19,
//                     format: 'image/jpeg',
//                     variant: 'ORTHOIMAGERY.ORTHOPHOTOS'
//                 }
//             }
//         }
//     },
//     OneMapSG: {
//         url: 'https://maps-{s}.onemap.sg/v3/{variant}/{z}/{x}/{y}.png',
//         options: {
//             variant: 'Default',
//             minZoom: 11,
//             maxZoom: 18,
//             bounds: [[1.56073, 104.11475], [1.16, 103.502]],
//             attribution: '<img src="https://docs.onemap.sg/maps/images/oneMap64-01.png" style="height:20px;width:20px;"/> New OneMap | Map data &copy; contributors, <a href="http://SLA.gov.sg">Singapore Land Authority</a>'
//         },
//         variants: {
//             Default: 'Default',
//             Night: 'Night',
//             Original: 'Original',
//             Grey: 'Grey',
//             LandLot: 'LandLot'
//         }
//     },
//     USGS: {
//         url: 'https://basemap.nationalmap.gov/arcgis/rest/services/USGSTopo/MapServer/tile/{z}/{y}/{x}',
//         options: {
//             maxZoom: 20,
//             attribution: 'Tiles courtesy of the <a href="https://usgs.gov/">U.S. Geological Survey</a>'
//         },
//         variants: {
//             USTopo: {},
//             USImagery: {
//                 url: 'https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/tile/{z}/{y}/{x}'
//             },
//             USImageryTopo: {
//                 url: 'https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryTopo/MapServer/tile/{z}/{y}/{x}'
//             }
//         }
//     },
//     WaymarkedTrails: {
//         url: 'https://tile.waymarkedtrails.org/{variant}/{z}/{x}/{y}.png',
//         options: {
//             maxZoom: 18,
//             attribution: 'Map data: {attribution.OpenStreetMap} | Map style: &copy; <a href="https://waymarkedtrails.org">waymarkedtrails.org</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
//         },
//         variants: {
//             hiking: 'hiking',
//             cycling: 'cycling',
//             mtb: 'mtb',
//             slopes: 'slopes',
//             riding: 'riding',
//             skating: 'skating'
//         }
//     },
//     OpenAIP: {
//         url: 'https://{s}.tile.maps.openaip.net/geowebcache/service/tms/1.0.0/openaip_basemap@EPSG%3A900913@png/{z}/{x}/{y}.{ext}',
//         options: {
//             attribution: '<a href="https://www.openaip.net/">openAIP Data</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-NC-SA</a>)',
//             ext: 'png',
//             minZoom: 4,
//             maxZoom: 14,
//             tms: true,
//             detectRetina: true,
//             subdomains: '12'
//         }
//     },
//     OpenSnowMap: {
//         url: 'https://tiles.opensnowmap.org/{variant}/{z}/{x}/{y}.png',
//         options: {
//             minZoom: 9,
//             maxZoom: 18,
//             attribution: 'Map data: {attribution.OpenStreetMap} & ODbL, &copy; <a href="https://www.opensnowmap.org/iframes/data.html">www.opensnowmap.org</a> <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>'
//         },
//         variants: {
//             pistes: 'pistes',
//         }
//     },
//     AzureMaps: {
//         url:
//             'https://atlas.microsoft.com/map/tile?api-version={apiVersion}'+
//             '&tilesetId={variant}&x={x}&y={y}&zoom={z}&language={language}'+
//             '&subscription-key={subscriptionKey}',
//         options: {
//             attribution: 'See https://docs.microsoft.com/en-us/rest/api/maps/render-v2/get-map-tile for details.',
//             apiVersion: '2.0',
//             variant: 'microsoft.imagery',
//             subscriptionKey: '<insert your subscription key here>',
//             language: 'en-US',
//         },
//         variants: {
//             MicrosoftImagery: 'microsoft.imagery',
//             MicrosoftBaseDarkGrey: 'microsoft.base.darkgrey',
//             MicrosoftBaseRoad: 'microsoft.base.road',
//             MicrosoftBaseHybridRoad: 'microsoft.base.hybrid.road',
//             MicrosoftTerraMain: 'microsoft.terra.main',
//             MicrosoftWeatherInfraredMain: {
//                 url:
//                 'https://atlas.microsoft.com/map/tile?api-version={apiVersion}'+
//                 '&tilesetId={variant}&x={x}&y={y}&zoom={z}'+
//                 '&timeStamp={timeStamp}&language={language}' +
//                 '&subscription-key={subscriptionKey}',
//                 options: {
//                     timeStamp: '2021-05-08T09:03:00Z',
//                     attribution: 'See https://docs.microsoft.com/en-us/rest/api/maps/render-v2/get-map-tile#uri-parameters for details.',
//                     variant: 'microsoft.weather.infrared.main',
//                 },
//             },
//             MicrosoftWeatherRadarMain: {
//                 url:
//                 'https://atlas.microsoft.com/map/tile?api-version={apiVersion}'+
//                 '&tilesetId={variant}&x={x}&y={y}&zoom={z}'+
//                 '&timeStamp={timeStamp}&language={language}' +
//                 '&subscription-key={subscriptionKey}',
//                 options: {
//                     timeStamp: '2021-05-08T09:03:00Z',
//                     attribution: 'See https://docs.microsoft.com/en-us/rest/api/maps/render-v2/get-map-tile#uri-parameters for details.',
//                     variant: 'microsoft.weather.radar.main',
//                 },
//             }
//         },
//     },
//     SwissFederalGeoportal: {
//         url: 'https://wmts.geo.admin.ch/1.0.0/{variant}/default/current/3857/{z}/{x}/{y}.jpeg',
//         options: {
//             attribution: '&copy; <a href="https://www.swisstopo.admin.ch/">swisstopo</a>',
//             minZoom: 2,
//             maxZoom: 18,
//             bounds: [[45.398181, 5.140242], [48.230651, 11.47757]]
//         },
//         variants: {
//             NationalMapColor: 'ch.swisstopo.pixelkarte-farbe',
//             NationalMapGrey: 'ch.swisstopo.pixelkarte-grau',
//             SWISSIMAGE: {
//                 options: {
//                     variant: 'ch.swisstopo.swissimage',
//                     maxZoom: 19
//                 }
//             }
//         }
//     }
// };