import { Link } from 'react-router-dom';
// import { GiCottonFlower, GiPlantSeed, GiPlantRoots, GiCarnivorousPlant } from 'react-icons/gi';
import '../../styles/global.css';
import '../../styles/pages/components/PropriedadeCard.css';
import { convert0To00 } from '../../utils/strings';
import { FarmVazioType } from '../CadastroPropriedade'

type Props = {
    farm: any,
    edit: boolean
}

function PropriedadeCard({ farm, edit }: Props) {
    console.log(farm);
    return (
        // <div className={farm.algodao_irregular || farm.soja_irregular ? 'info-farm-irregular' : 'info-farm-regular'}>
        <div className={'info-farm-regular'}>
            <div className={'info-farm'}>
                {edit ?
                    <div>
                        <div className='name'>{farm.nome&&farm.nome.toUpperCase()}</div>
                        {/* <div className='name'>
                            <div style={{ color: '#b00' }}>{farm.soja_irregular ? '[' : ''}
                                {farm.soja ? <GiPlantSeed color={farm.soja_irregular ? '#b00' : '#000'} /> : ''}
                                {farm.soja_irregular ? '!]' : ''}
                                {farm.algodao_irregular ? '[' : ''}
                                {farm.algodao ? <GiCottonFlower color={farm.algodao_irregular ? '#b00' : '#000'} /> : ''}
                                {farm.algodao_irregular ? '!]' : ''}
                            </div>
                        </div> */}

                        <div className='label'>LOCALIDADE:</div>
                        <input className='field' value={farm.localidade} />
                        <div className='label'>ACESSO:</div>
                        <input className='field' value={farm.acesso} />
                        <div className='label'>PROPRIETÁRIO:</div>
                        <input className='field' value={farm.proprietario.nome_razao&&farm.proprietario.nome_razao.toUpperCase()} />
                    </div> :
                    <Link className='link' to={'/propriedade'} state={farm}>
                        <div className='name'>{farm.nome.toUpperCase()}</div>
                        {/* <div className='name'>
                             <div style={{ color: '#b00' }}>{farm.soja_irregular ? '[' : ''}
                                {farm.soja ? <GiPlantSeed color={farm.soja_irregular ? '#b00' : '#000'} /> : ''}
                                {farm.soja_irregular ? '!]' : ''}
                                {farm.algodao_irregular ? '[' : ''}
                                {farm.algodao ? <GiCottonFlower color={farm.algodao_irregular ? '#b00' : '#000'} /> : ''}
                                {farm.algodao_irregular ? '!]' : ''}
                            </div>
                        </div> */}
                        <div className='label'>MUNICÍPIO</div>
                        <input className='field' value={farm.municipio.nome} />
                        {/* <div className='label'>INICIO VAZIO</div>
                        <input className='field' value={convert0To00(farm.vazio.dia_inicio) + '/' + convert0To00(farm.vazio.mes_inicio)} />
                        <div className='label'>FINAL VAZIO</div>
                        <input className='field' value={convert0To00(farm.vazio.dia_final) + '/' + convert0To00(farm.vazio.mes_final)} /> */}
                        <div className='label'>LOCALIDADE</div>
                        <input className='field' value={farm.localidade.toUpperCase()} />
                        <div className='label'>ACESSO</div>
                        <input className='field' value={farm.acesso.toUpperCase()} />
                        <div className='label'>PROPRIETÁRIO</div>
                        <input className='field' value={farm.proprietario&&farm.proprietario.nome_razao&&farm.proprietario.nome_razao.toUpperCase()} />
                    </Link>
                }
            </div>
        </div>
    );
}

export default PropriedadeCard;